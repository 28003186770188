import React from 'react';

const EmailTemplate = ({ data }) => {
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.headerTitle}>Contact Us Notification</h1>
            </div>
            <div style={styles.content}>
                <h2 style={styles.contentTitle}>You have a new contact form submission!</h2>
                <p><strong>Name:</strong> {data.name}</p>
                <p><strong>Email:</strong> {data.email}</p>
                <p><strong>Subject:</strong> {data.subject}</p>
                <div style={styles.info}>
                    <p>{data.message}</p>
                </div>
            </div>
            <div style={styles.footer}>
                <p>&copy; 2024 Tragedel. All rights reserved.</p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: '20px auto',
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    header: {
        backgroundColor: '#007BFF',
        color: '#ffffff',
        padding: '20px',
        textAlign: 'center',
    },
    headerTitle: {
        margin: 0,
    },
    content: {
        padding: '20px',
    },
    contentTitle: {
        color: '#333',
    },
    info: {
        border: '1px solid #e1e1e1',
        borderRadius: '4px',
        padding: '10px',
        margin: '10px 0',
        backgroundColor: '#f9f9f9',
    },
    footer: {
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
        padding: '10px',
        fontSize: '12px',
        color: '#999',
    },
    footerLink: {
        color: '#007BFF',
        width: '150px',
        textDecoration: 'none',
    },
};

export default EmailTemplate;
