import React from 'react';
import Theme from '../../theme/Theme';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { LANG } from '../../translation/translation';
import EmailTemplate from '../../Helpers/EmailTemplate';
import { Email } from '../../Helpers/Email';
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom';

function Contact({ data }) {
    const mapStyles = {
        height: "400px",
        width: "100%"
    };

    const defaultCenter = {
        lat: data?.websiteInformation[0]?.latitude || 36.834386,
        lng: data?.websiteInformation[0]?.longitude || 10.237106
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const formObject = Object.fromEntries(formData.entries());
        const emailHtml = renderToString(<EmailTemplate data={formObject} />);

        Email.send({
            Host: "smtp.elasticemail.com",
            Port: "2525",
            Username: "clientragedel@gmail.com",
            Password: "C2D1F6F8A288D3D0A4759F043B7E6B3653FE",
            To: "commercial.department@tragedel.com",
            From: formObject.email,
            Subject: formObject.subject,
            Body: emailHtml
        }).then((message) => alert("mail sent successfully"));
    };

    return (
        <Theme data={data}>
            <h1 className="entry-title">{LANG === 'en' ? 'Contact Us' : 'Nous contacter'}</h1>
            <div className="slider-area ">
                <div className="single-slider hero-overly slider-height2 d-flex align-items-center" style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/contact-img.jpg"}) center/cover no-repeat fixed` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="hero-cap pt-100">
                                    <h2>{LANG == 'en' ? 'Contact Us' : 'Contactez-nous'}</h2>
                                    <nav aria-label="breadcrumb ">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/home">{LANG == 'en' ? "Home" : "Accueil"}</Link>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <a href="#">{LANG == 'en' ? 'Contact Us' : 'Contactez-nous'}</a>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                            <div className="section-tittle section-tittle7 mb-50">
                                <div className="front-text">
                                    <h2 className="">{LANG === "en" ? "Leave us a message" : "Laissez-nous un message"}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-home"></i></span>
                                <div className="media-body">
                                    <h3>{data?.websiteInformation[0].address.split(',')[0]}</h3>
                                    <p>{data?.websiteInformation[0].address.replace(data?.websiteInformation[0].address.split(',')[0] + ",", '')}</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                <div className="media-body">
                                    <h3>{data?.websiteInformation[0].phone}</h3>
                                    <p>{LANG === 'en' ? "Mon - Fri 8:00 - 17:30, Saturday, Sunday - CLOSED" : "Du lundi au vendredi de 8h00 à 17h30, samedi et dimanche - FERMÉ"}</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-email"></i></span>
                                <div className="media-body">
                                    <h3>{data?.websiteInformation[0].email}</h3>
                                    <p>{LANG === 'en' ? "Send us your query anytime!" : "Envoyez-nous votre requête à tout moment !"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="form-contact contact_form" id="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input className="form-control valid" name="name" id="name" type="text" placeholder={LANG === 'en' ? 'Enter your name' : 'Entrez votre nom'} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input className="form-control valid" name="email" id="email" type="email" placeholder={LANG === 'en' ? 'Enter email address' : 'Entrez l\'adresse e-mail'} required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" name="subject" id="subject" type="text" placeholder={LANG === 'en' ? 'Enter Subject' : 'Entrez le sujet'} required />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control w-100" name="message" id="message" cols="30" rows="9" placeholder={LANG === 'en' ? 'Enter Message' : 'Entrez le message'} required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <button type="submit" className="button button-contactForm btn">{LANG === 'en' ? 'Send Message' : 'Envoyer le message'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="d-none d-sm-block mb-5 pb-4">
                        <LoadScript googleMapsApiKey="AIzaSyAo1viD-Ut0TzXTyihevwuf-9tv_J3dPa0">
                            <GoogleMap
                                mapContainerStyle={mapStyles}
                                zoom={13}
                                center={defaultCenter}
                            >
                                <Marker position={defaultCenter} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
            </section>
        </Theme>
    );
}

export default Contact;
