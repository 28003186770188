import React, { useEffect, useState } from 'react';
import franceFlag from '../assets/icon/france.png'
import englishFlag from '../assets/icon/usa.png'
let selectedLanguage = null;

function GoogleTranslate() {

    let [defaultLangauge, setDefaultLanguage] = useState(getLatestLanguage())
    // let [showSelect, setShowSelect] = useState(false)

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //     script.async = true;
    //     document.body.appendChild(script);
    //     window.googleTranslateElementInit = () => {
    //         new window.google.translate.TranslateElement(
    //             {
    //                 pageLanguage: "en",
    //                 includedLanguages: 'en,fr',
    //                 autoDisplay: false,
    //             },
    //             'google-translate-widget'
    //         );
    //     };

    //     setTimeout(function () {
    //         setShowSelect(true)
    //         onlanguagechange(defaultLangauge)
    //     }, 1000);

    //     return () => {
    //         document.body.removeChild(script);
    //         delete window.googleTranslateElementInit;
    //     };
    // }, []);

    function getLatestLanguage() {
        let vall = localStorage.getItem('selectedLanguage') || 'fr'
        return vall
    }


    function onlanguagechange(val) {
        try {
            localStorage.setItem('selectedLanguage', val);
            window.location.reload();
        } catch (e) { }
    }

    return (
        <>
            <select
                id='google_translate_element'
                value={defaultLangauge}
                style={{
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    borderColor: '#0b1c39',
                    position: 'absolute',
                    width: '75px',
                    top: '-20px',
                    height: '40px',
                    right: '10px'
                }}
                onChange={(e) => onlanguagechange(e.target.value)}
            >
                <option value="en">English</option>
                <option value="fr">French</option>
            </select>
        </>
    );
}

export default GoogleTranslate;
