import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GoogleTranslate from '../components/GoogleTranslate';
import { LANG } from '../translation/translation';


function Theme({ children, data }) {
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '/assets/js/main.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <div id="preloader-active">
                <div className="preloader d-flex align-items-center justify-content-center">
                    <div className="preloader-inner position-relative">
                        <div className="preloader-circle"></div>
                        <div className="preloader-img pere-text">
                            <img src="assets/img/logo/loder-logo.png" style={{ height: '43px', width: '94px', maxWidth: '94px' }} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <>
                <header>
                    <div className="header-area header-transparent">
                        <div className="main-header ">
                            {/* <div className="header-top d-none d-lg-block">
                                <div className="container-fluid">
                                    <div className="col-xl-12">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="header-info-left">
                                                <ul>
                                                    <li>{data?.websiteInformation[0].phone}</li>
                                                    <li>{data?.websiteInformation[0].email}</li>
                                                    <li>Mon - Fri 8:00 - 17:30, Saturday, Sunday - CLOSED</li>
                                                </ul>
                                            </div>
                                            <div className="header-info-right">
                                                <ul className="header-social">
                                                    <li><Link to={data?.socialMedia[0].facebook}><i className="fab fa-twitter"></i></Link></li>
                                                    <li><Link to={data?.socialMedia[0].facebook}><i className="fab fa-facebook-f"></i></Link></li>
                                                    <li><Link to={data?.socialMedia[0].linkedin}><i className="fab fa-linkedin-in"></i></Link></li>
                                                    <li><Link to={data?.socialMedia[0].facebook}><i className="fab fa-google-plus-g"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="header-bottom header-sticky">
                                <div className="container-fluid">
                                    <div className="row align-items-center">
                                        <div className="col-xl-2 col-lg-2 col-md-1">
                                            <div className="logo">
                                                <Link to="/" className="big-logo"><img style={{ width: '150px' }} src="assets/img/logo/logo.png" alt="" /></Link>
                                                <Link to="/" className="small-logo"><img style={{ width: '150px' }} src="assets/img/logo/loder-logo.png" alt="" /></Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8">
                                            <div className="main-menu f-right d-none d-lg-block">
                                                <nav>
                                                    <ul id="navigation">
                                                        <li><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                        <li className="dropdown">
                                                            <Link>
                                                                {LANG === 'en' ? 'About Us' : 'À propos de nous'}
                                                            </Link>
                                                            <ul className="submenu">
                                                                <li><Link to="/about">{LANG === 'en' ? 'Overview' : 'Aperçu'}</Link></li>
                                                                <li><Link to="/clients">{LANG === 'en' ? 'Our Clients' : 'Nos clients'}</Link></li>
                                                                <li><Link to="/founders-message">{LANG === 'en' ? 'Chairman Message' : 'Message du président'}</Link></li>
                                                            </ul>
                                                        </li>
                                                        <li><Link to="/projects">{LANG == 'en' ? 'Our Projects' : 'Projets'}</Link></li>
                                                        <li><Link to="/services">{LANG == 'en' ? 'Our Services' : 'Nos Services'}</Link></li>
                                                        <li><Link to="/blog">{LANG == 'en' ? 'News' : 'Nouvelles'}</Link></li>
                                                        <li><Link to="/contact">{LANG == 'en' ? 'Contact Us' : 'Contactez-nous'}</Link></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-lg-2 col-md-3">
                                            <div className="header-right-btn f-right d-none d-lg-block">
                                                <Link to="/contact" className="btn">{LANG == 'en' ? 'Contact Us' : 'Contactez-nous'}</Link>
                                            </div>
                                        </div>
                                        <div className="header-right-btn" style={{ position: 'absolute', right: '10px' }}>
                                            <GoogleTranslate />
                                        </div>
                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {children}
                <footer>
                    <div className="footer-main">
                        <div className="footer-area footer-padding" style={{
                            backgroundImage: `url(${"assets/img/gallery/section-bg2.jpg"})`,
                            paddingBottom: '0px'
                        }}>
                            <div className="container">
                                <div className="row  justify-content-between">
                                    <div className="col-lg-3 col-md-4 col-sm-8">
                                        <div className="single-footer-caption mb-30">
                                            <div className="footer-logo" style={{ marginTop: '-25px' }}>
                                                <Link to="/"><img src="assets/img/logo/logo.png" style={{ width: '264px' }} alt="" /></Link>
                                            </div>
                                            <div className="footer-tittle" style={{ paddingLeft: '50px' }}>
                                                <div className="footer-pera" style={{ display: 'inline-flex', gap: '10px' }}>
                                                    <li className='social_icon'><Link to={"https://www.youtube.com/@tragedel8065"} style={{ color: '#0b1c39' }}><i className="fab fa-youtube"></i></Link></li>
                                                    <li className='social_icon'><Link to={data?.socialMedia[0].facebook} style={{ color: '#0b1c39' }}><i className="fab fa-facebook-f"></i></Link></li>
                                                    <li className='social_icon'><Link to={data?.socialMedia[0].linkedin} style={{ color: '#0b1c39' }} ><i className="fab fa-linkedin-in"></i></Link></li>
                                                    {/* <li className='social_icon'><Link to={data?.socialMedia[0].facebook} style={{ color: '#0b1c39' }}><i className="fab fa-google-plus-g"></i></Link></li> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-5">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>{LANG == "en" ? "Quick Links" : "Liens rapides"}</h4>
                                                <ul>
                                                    <li><Link to="/about">{LANG == 'en' ? 'About' : 'A propos'}</Link></li>
                                                    <li><Link to="/services">{LANG == 'en' ? 'Services' : 'Nos Services'}</Link></li>
                                                    <li><Link to="/projects">{LANG == 'en' ? 'Projects' : 'Projets'}</Link></li>
                                                    <li><Link to="/contact">{LANG == 'en' ? 'Contact' : 'Contactez-nous'}</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-7">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>{LANG == 'en' ? 'Contact' : 'Contactez-nous'}</h4>
                                                <div className="footer-pera">
                                                    <p className="info1">{data?.websiteInformation[0].address}</p>
                                                </div>
                                                <ul>
                                                    <li><Link to="#">{LANG == "en" ? "Phone" : "Téléphone"}: {data?.websiteInformation[0].phone}</Link></li>
                                                    <li><Link to="#">{LANG == "en" ? "Cell" : "Cellule"}: {data?.websiteInformation[0].phone}</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-form">
                                                <div className="footer-tittle">
                                                    <h4>{LANG == "en" ? "Certified By" : "Certifié par"}</h4>
                                                </div>
                                                {/* <div id="mc_embed_signup">
                                                    <form target="_blank" action="#" method="get" className="subscribe_form relative mail_part" noValidate={true}>
                                                        <input type="email" name="EMAIL" id="newsletter-form-email" placeholder=" Email Address " className="placeholder hide-on-focus" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Email Address'} />
                                                        <div className="form-icon">
                                                            <button type="submit" name="submit" id="newsletter-submit" className="email_icon newsletter-submit button-contactForm">
                                                                SIGN UP
                                                            </button>
                                                        </div>
                                                        <div className="mt-10 info"></div>
                                                    </form>
                                                </div> */}
                                            </div>
                                            <div className="map-footer" style={{ display: 'inline-flex', gap: '5px' }}>
                                                <img src="assets/img/logo/certified.png" style={{ width: '200px' }} alt="" />
                                                {/* <img src="assets/img/logo/certified1.png" style={{ width: '170px', marginTop: '10px'}} alt="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-xl-12 ">
                                        <div className="footer-copy-right">
                                            <p>Tragedel &copy;{currentYear} All rights reserved | {data?.websiteInformation[0].name} | Design and Development by Tragedel</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div className="contact-sidebar">
                    <div className="contact-item contact-side" style={{ width: '47px' }}>
                        <i className="ti-mobile" style={{ height: '130px', display: 'grid' }}>
                            <Link to={"/contact"} style={{ marginLeft: '0px', writingMode: 'vertical-lr', height: '95px', backgroundColor: 'transparent' }}>{LANG == 'en' ? "Contact Us" : "Nous Contactez"}</Link>
                        </i>
                    </div>
                    <div className="contact-item phone-side">
                        <i className="ti-mobile"></i>
                        <a href={"tel:" + data?.websiteInformation[0].phone}>{data?.websiteInformation[0].phone}</a>
                    </div>
                    <div className="contact-item email-side">
                        <i className="ti-email"></i>
                        <a href={"mailto:" + data?.websiteInformation[0].email}>{data?.websiteInformation[0].email}</a>
                    </div>
                </div>
            </>
        </div >
    );
}

export default Theme;
