/* SmtpJS.com - v3.0.0 */
export const Email = {
    send: function (a) {
        return new Promise(function (n, e) {
            a.nocache = Math.floor(1e6 * Math.random() + 1); // Separate statement
            a.Action = "Send"; // Separate statement
            var t = JSON.stringify(a);
            Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (response) {
                n(response);
            });
        });
    },
    ajaxPost: function (url, data, callback) {
        var request = Email.createCORSRequest("POST", url);
        if (request) {
            request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            request.onload = function () {
                var response = request.responseText;
                if (callback) {
                    callback(response); // Call the callback if it exists
                }
            };
            request.send(data);
        } else {
            console.error("CORS is not supported in this environment.");
        }
    },
    ajax: function (url, callback) {
        var request = Email.createCORSRequest("GET", url);
        if (request) {
            request.onload = function () {
                var response = request.responseText;
                if (callback) {
                    callback(response); // Call the callback if it exists
                }
            };
            request.send();
        } else {
            console.error("CORS is not supported in this environment.");
        }
    },
    createCORSRequest: function (method, url) {
        var request = new XMLHttpRequest();
        if ("withCredentials" in request) {
            request.open(method, url, true);
        } else {
            request = null; // CORS is not supported
        }
        return request;
    }
};
