import React, { useEffect, useState } from 'react';
import Home from './pages/home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import { createClient } from 'contentful';
import Service from './pages/service/Service';
import Projects from './pages/projects/Projects';
import Services from './pages/Services/Services';
import Blog from './pages/blog/Blog';
import Project from './pages/projects/Project';
import Clients from './pages/about/Clients';
import Chairman from './pages/about/Chairman';
import ChairmanDetail from './pages/about/ChairmanDetail';
import { DUMMY_DATA } from './Helpers/dummy';

const contentfulClient = createClient({
  accessToken: 'owkS3BtGQZY7HT5C2UJkuWd9R_d2f9kXmg6iIJhiEw0',
  space: '41chegrmhit0',
});

function App() {

  const [data, setData] = useState(null)
  const fetchEntries = async () => {
    try {
      const response = await contentfulClient.getEntries();
      const entries = response.items;
      const groupedData = entries.reduce((acc, entry) => {
        const contentTypeId = entry.sys.contentType.sys.id;
        if (!acc[contentTypeId]) {
          acc[contentTypeId] = [];
        }
        acc[contentTypeId].push(entry);
        return acc;
      }, {});

      let data = {}

      Object.keys(groupedData).forEach(key => {
        const contentTypeId = key;
        for (let i = 0; i < groupedData[contentTypeId].length; i++) {
          if (!data[contentTypeId]) {
            data[contentTypeId] = []
          }
          data[contentTypeId].push(groupedData[contentTypeId][i].fields)
        }
      })
      console.log(data)
      setData(data);
    } catch (error) {
      setData(DUMMY_DATA)
    }
  };

  useEffect(() => {
    if (!data) {
      fetchEntries();
    }
  }, [data]); // Only fetch data if it's not already fetched

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home data={data} />} />
        <Route path="/about" element={<About data={data} />} />
        <Route path="/projects" element={<Projects data={data} />} />
        <Route path="/services" element={<Services data={data} />} />
        <Route path="/blog" element={<Blog data={data} />} />
        <Route path="/contact" element={<Contact data={data} />} />
        <Route path="/service" element={<Service data={data} />} />
        <Route path="/project" element={<Project data={data} />} />
        <Route path="/clients" element={<Clients data={data} />} />
        <Route path="/chairman" element={<Chairman data={data} />} />
        <Route path="/founders-message" element={<ChairmanDetail data={data} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
