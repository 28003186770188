import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import { LANG } from '../../translation/translation';

function Blog({ data }) {

    const uniqueServices = [...new Set(data?.project.map(project => project.service))];

    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">All Blogs</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/about.jpg"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == 'en' ? 'Tragedel News' : 'Actualités Tragédel'}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == 'en' ? 'Blog' : 'Blogues'}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="latest-news-area section-padding30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                <div className="section-tittle section-tittle7 mb-50">
                                    <div className="front-text">
                                        <h2 className="">{LANG == "en" ? "News" : "Nouvelles"}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {data?.news
                                .map((blog, index) => {
                                    const { title, titleFrench, images, date, category, categoryFrench } = blog;
                                    return (
                                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                            <div className="single-news mb-30" style={{ height: '780px' }}>
                                                <div className="news-img">
                                                    <img src={images[0].fields.file.url} alt="" />
                                                    {/* <div className="news-date text-center">
                                                        <span>{date}</span>
                                                    </div> */}
                                                </div>
                                                <div className="news-caption" style={{ padding: '14px 11px 13px 19px' }}>
                                                    <ul className="david-info">
                                                        <li> | &nbsp; &nbsp;  {LANG == "en" ? category : categoryFrench}</li>
                                                    </ul>
                                                    <h2><Link>{LANG == "en" ? title : titleFrench}</Link></h2>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </main>
        </Theme >
    );
}

export default Blog;
