import React from 'react';
import Theme from '../../theme/Theme';
import { Link, useNavigate } from 'react-router-dom';
import { LANG } from '../../translation/translation';
import { truncateStr } from '../../Helpers/api';

function Projects({ data }) {

    let uniqueServices = LANG == "en" ? [...new Set(data?.project?.map(project => project.service))] : [...new Set(data?.project?.map(project => project.serviceFrench))]
    const navigate = useNavigate();

    const handleProjectClick = (project) => {
        console.log(project)
        localStorage.setItem('selected-project', JSON.stringify(project));
        navigate(`/project`);
    };


    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">All Projects</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/ser.png"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == "en" ? "Our Projects" : "Nos projets"}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == 'en' ? "Projects" : 'Projets'}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area section-padding30">
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2>{LANG == 'en' ? "Projects Overview" : 'Nos projets'}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content active" id="nav-tabContent">
                                    {/* Show all projects */}
                                    <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
                                        <div className="project-caption">
                                            <div className="row">
                                                {data?.project.map((project, index) => {
                                                    const { name, nameFrench, city, countryFrench, images, abbreviation } = project;
                                                    return (
                                                        <div className="col-lg-4 col-md-6">
                                                            <div className="single-project mb-30 project-card" style={{ height: '512px' }}>
                                                                <div className="project-img">
                                                                    <img src={images[0].fields.file.url} alt="" style={{ height: '242px' }} />
                                                                </div>
                                                                <div style={{ margin: '7px', fontWeight: '450', color: 'white', border: '2px solid #0b1c3957', background: '#0b1c3957', borderRadius: '10px', padding: '4px', top: '0px', left: '0px', position: 'absolute', height: '36px' }}>{LANG == "en" ? city : countryFrench}</div>
                                                                <div className="project-cap" style={{ height: '270px' }}>
                                                                    <h4 style={{ fontSize: '22px' }}>{truncateStr(LANG == "en" ? name : nameFrench)}</h4>
                                                                    <div style={{ position: 'absolute', bottom: '0px' }}>
                                                                        <p style={{ color: 'red' }}>Client: {project?.client?.fields?.title}</p>
                                                                        <p style={{ marginTop: '-10px', color: 'red' }}>Funder: {project?.funder?.fields?.title}</p>
                                                                    </div>
                                                                    {/* <a style={{color: 'red', position: 'absolute', bottom: '15px', fontWeight: 500}}>View Project</a> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Dynamic project filtering by service */}
                                    {uniqueServices.map((service, index) => {
                                        const serviceSlug = service.toLowerCase().replace(/\s+/g, '-');
                                        return (
                                            <div
                                                key={index}
                                                className="tab-pane fade"
                                                id={`nav-${serviceSlug}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-${serviceSlug}-tab`}
                                            >
                                                <div className="project-caption">
                                                    <div className="row">
                                                        {data?.project
                                                            .filter((project) => project.service === service)
                                                            .map((project, projectIndex) => {
                                                                const { name, nameFrench, city, countryFrench, images } = project;
                                                                return (
                                                                    <div className="col-lg-4 col-md-6" onClick={() => handleProjectClick(project)}>
                                                                        <div className="single-project mb-30" style={{ height: '440px' }}>
                                                                            <div className="project-img">
                                                                                <img src={images[0].fields.file.url} alt="" style={{ height: '242px' }} />
                                                                            </div>
                                                                            <div style={{ color: 'white', background: "red", padding: "10px", top: "0px", right: "0px", position: "absolute", height: "43px" }}>{LANG == "en" ? city : countryFrench}</div>
                                                                            <div className="project-cap" style={{ height: '203px' }}>
                                                                                <a className="plus-btn"><i className="ti-plus"></i></a>
                                                                                <h4><a>{truncateStr(LANG == "en" ? name : nameFrench)}</a></h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="project-area" style={{ marginBottom: '120px' }}>
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12" style={{textAlign: 'center'}}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 className="">{LANG == "en" ? "Our Clients" : "Nos clients"}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <section className="customer-logos slider">
                                    {data?.clients
                                        .map((client, index) => {
                                            const { image } = client;
                                            return (
                                                <div className="slide"> <img style={{ width: '140px', height: '140px' }} src={image.fields.file.url} /> </div>
                                            );
                                        })}
                                </section>
                            </div>
                        </div>
                    </div>
                </section>    */}

            </main>
        </Theme >
    );
}

export default Projects;
