import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import Service from '../home/Service';
import { gallery, gallery_french, LANG, services, services_french } from '../../translation/translation';
import Service1 from '../home/Service1';

function Services({ data }) {
    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Services</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/ser.png"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == 'en' ? "Our Services" : "Nos Services"}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == 'en' ? "Services" : "Nos Services"}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area" style={{ paddingTop: '100px' }}>
                    <div className="container services-container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <p style={{ fontSize: '40px', fontFamily: 'auto', lineHeight: '2.2rem' }}>{LANG == "en" ? "Efficiency, innovation and know-how: your path to the right solution" : "Efficacité, innovation et savoir-faire : votre chemin vers la bonne solution"}</p>
                                            {LANG == "en" ?
                                                <p style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 400, letterSpacing: 'normal', lineHeight: '28px', fontFamily: 'auto' }}>Our activities and expertise span the entire spectrum of this dynamic field, embracing it’s full range of diversity and complexity. With our diversified range of services, we’re equipped to tackle virtually any project for you. Our construction standards have been rigorously tested both internally and externally, ensuring high quality in every project we undertake. <br /> <br /> From the outset of a project, we focus on optimal connectivity to deliver projects efficiently in terms of time, cost and quality.</p>
                                                :
                                                <p style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 400, letterSpacing: 'normal', lineHeight: '28px', fontFamily: 'auto' }}>Nos activités et notre expertise couvrent tout le spectre de ce domaine dynamique, embrassant toute sa diversité et sa complexité. Avec notre gamme diversifiée de services, nous sommes équipés pour relever pratiquement tous les projets pour vous. Nos normes de construction ont été rigoureusement testées à la fois en interne et en externe, garantissant une qualité élevée dans chaque projet que nous entreprenons. <br /> <br /> Dès le début d’un projet, nous nous concentrons sur une connectivité optimale pour livrer les projets de manière efficace en termes de temps, de coût et de qualité.</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-heading mb-35" style={{ paddingTop: '50px' }}>
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <p style={{ fontSize: '40px', fontFamily: 'auto', lineHeight: '2.2rem' }}>{LANG == "en" ? "Explore our services" : "Découvrez nos services"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content active" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="project-caption">
                                            <div className="row">
                                                {data?.services.map((val, index) => {
                                                    return <Service1 key={index} service={val} />
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Theme >
    );
}

export default Services;
