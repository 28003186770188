import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import { LANG } from '../../translation/translation';

function Chairman({ data }) {
    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Our Chairman</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/about.jpg"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>A MESSAGE FROM KAMEL CHARFEDDINE</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">Our Chairman</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area section-padding30">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <img src="assets/img/hero/chairman.jpg" style={{ width: '100%' }} />
                                    <div style={{ position: 'absolute', height: '220px', width: "450px", padding: '10px', backgroundColor: 'white', left: '0px', bottom: '0px' }}>
                                        <h3 style={{ marginLeft: '10px', fontSize: '25px', marginTop: '5px', fontFamily: 'Optima LT W02 Roman', fontWeight: '400' }}>"WE ARE SHARPENING OUR STRATEGY TO BE ONE OF THE WORLD'S MOST VALUABLE, MOST INNOVATIVE AND MOST ADMIRED COMPANIES"</h3>
                                        <p className='chairman-tag'>FOUNDER | KAMEL CHARFEDDINE</p>
                                        <Link to={'/founders-message'}><button style={{ marginLeft: '10px' }} className='btn'>READ FOUNDER'S LETTER</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Theme >
    );
}

export default Chairman;
