import React, { useEffect, useState } from 'react';
import Theme from '../../theme/Theme';
import { Link, useNavigate } from 'react-router-dom';
import Service from './Service';
import { LANG, services, services_french } from '../../translation/translation';
import { truncateStr } from '../../Helpers/api';

function Home({ data }) {
    const [visibleService, setVisibleService] = useState(0)
    let uniqueServices = LANG == "en" ? [...new Set(data?.project?.map(project => project.service))] : [...new Set(data?.project?.map(project => project.serviceFrench))]
    const slides = Array.from({ length: 3 }, (_, i) => i + 1);

    const navigate = useNavigate();


    const handleProjectClick = (project) => {
        localStorage.setItem('selected-project', JSON.stringify(project));
        navigate(`/project`);
    };

    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Home</h1>
                <div className="slider-area ">
                    <div className="slider-active">
                        <div className="single-slider hero-overly slider-height d-flex align-items-center" style={{ backgroundImage: `url(assets/img/projects/image1.jpg)` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <div className="hero__caption">
                                            <div className="hero-text1">
                                                <span data-animation="fadeInUp" data-delay=".3s">{LANG == "en" ? "Pioneers in Excellence: Building the Future of Quality Construction and Infrastructure" : "Pionniers de l’excellence : bâtir l’avenir d’une construction et d’infrastructures de qualité"}</span>
                                            </div>
                                            <h1 data-animation="fadeInUp" data-delay=".5s">Tragedel</h1>
                                            <div className="stock-text" data-animation="fadeInUp" data-delay=".8s">
                                                <h2>{LANG == "en" ? "Energy across the universe" : "L'énergie à travers l'univers"}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-slider hero-overly slider-height d-flex align-items-center" style={{ backgroundImage: `url(assets/img/projects/image2.jpg)` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <div className="hero__caption">
                                            <div className="hero-text1">
                                                <span data-animation="fadeInUp" data-delay=".3s">{LANG == "en" ? "Pioneers in Excellence: Building the Future of Quality Construction and Infrastructure" : "Pionniers de l’excellence : bâtir l’avenir d’une construction et d’infrastructures de qualité"}</span>
                                            </div>
                                            <h1 data-animation="fadeInUp" data-delay=".5s">Tragedel</h1>
                                            <div className="stock-text" data-animation="fadeInUp" data-delay=".8s">
                                                <h2>{LANG == "en" ? "Energy across the universe" : "L'énergie à travers l'univers"}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-slider hero-overly slider-height d-flex align-items-center" style={{ backgroundImage: `url(assets/img/projects/image3.jpg)` }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <div className="hero__caption">
                                            <div className="hero-text1">
                                                <span data-animation="fadeInUp" data-delay=".3s">{LANG == "en" ? "Pioneers in Excellence: Building the Future of Quality Construction and Infrastructure" : "Pionniers de l’excellence : bâtir l’avenir d’une construction et d’infrastructures de qualité"}</span>
                                            </div>
                                            <h1 data-animation="fadeInUp" data-delay=".5s">Tragedel</h1>
                                            <div className="stock-text" data-animation="fadeInUp" data-delay=".8s">
                                                <h2>{LANG == "en" ? "Energy across the universe" : "L'énergie à travers l'univers"}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area  section-padding30">
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 className="">{LANG == "en" ? services : services_french}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {data?.services.map((service, index) => {
                                    return <div className="row" style={{ display: visibleService == index ? 'flex' : 'none' }}>
                                        <div className="col-lg-5 col-md-6 col-sm-12" style={{ height: '415px', overflow: 'hidden' }}>
                                            <h2>{LANG == "en" ? service.name : service.titleFrench}</h2>
                                            <p>{LANG == "en" ? service?.description : service?.descriptionFrench1}</p>

                                            <div style={{ bottom: '0px', position: 'absolute', width: '96%', display: 'inline-flex', gap: '40px' }}>
                                                {data?.services.map((service1, index1) => {
                                                    let color = (visibleService == index1) ? '#FF0000' : '#0b1c39';
                                                    return <div onClick={() => setVisibleService(index1)}>
                                                        <div style={{ width: '60px', height: '60px', border: visibleService == index1 ? '1px solid red' : '1px solid #0b1c39', borderRadius: '30px' }}>
                                                            <div style={{ marginTop: '19px', marginLeft: '19px', backgroundColor: color, borderRadius: '10px', width: '20px', height: '20px' }}></div>
                                                        </div>
                                                        <p>{LANG == "en" ? service1.name : service1.titleFrench}</p>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 col-sm-12">
                                            <img src={service.image.fields.file.url} style={{ borderRadius: '8px', width: '90%', height: '400px' }} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="tab-content active" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="project-caption">
                                            <div className="row">
                                                {data?.services.map((val, index) => {
                                                    return <Service key={index} service={val} />
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>

                <section className="contact-with-area count-area" style={{ marginTop: '70px', backgroundImage: 'url(assets/img/gallery/section-bg2.jpg' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/fire.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>30</span>
                                            </div>
                                            <span>{LANG == "en" ? "AVERAGE ANNUAL REVENUES" : "CHIFFRE D’AFFAIRES ANNUEL MOYEN ($)"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/persons.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>50</span>
                                            </div>
                                            <span>{LANG == "en" ? "ENGINEERS" : "INGENIERS"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/transformer.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>36</span>
                                            </div>
                                            <span>{LANG == "en" ? "SUPERVISION RATE" : "TAUX D'ENCADREMENT"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/fire.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>120</span>
                                            </div>
                                            <span>{LANG == "en" ? "SUPERVISORS" : "SUPERVISEURS"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/persons.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>36</span>
                                            </div>
                                            <span>{LANG == "en" ? "STAFF" : "PERSONNEL"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="count-clients">
                                    <div className="single-counter">
                                        <div className="count-text" style={{ textAlign: 'center' }}>
                                            <img src='assets/img/icons/transformer2.png' />
                                            <div className="count-number" style={{ textAlign: 'center' }}>
                                                <span className="counter" style={{ fontSize: '30px', fontWeight: '600', color: 'black' }}>120</span>
                                            </div>
                                            <span>{LANG == "en" ? "TECHNICIANS" : "TECHNICIENS"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <div className="latest-news-area section-padding30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                <div className="section-tittle section-tittle7 mb-50">
                                    <div className="front-text">
                                        <h2 className="">{LANG == "en" ? "News & Blogs" : "Dernières nouvelles"}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {data?.news
                                .map((blog, index) => {
                                    const { title, titleFrench, images, date, category, categoryFrench } = blog;
                                    if (category == "Certification") {
                                        return (
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                <div className="single-news mb-30" style={{ height: '540px' }}>
                                                    <div className="news-img">
                                                        <a target='_blank' href={images[0].fields.file.url}><img src={images[0].fields.file.url} alt="" /></a>
                                                        {/* <div className="news-date text-center">
                                                            <span>{date}</span>
                                                        </div> */}
                {/* </div>

                                                </div>
                                            </div> */}
                {/*                                        
                            
                        </div>
                    </div> */}
                {/* </div> */}
            </main>
        </Theme >
    );
}

export default Home;
