import React, { useEffect, useState } from 'react';
import Theme from '../../theme/Theme';
import { LANG } from '../../translation/translation';

function Service({ data }) {
    const [service, setService] = useState(null);

    useEffect(() => {
        let selected = localStorage.getItem('selected-service')
        setService(JSON.parse(selected))
    }, [])

    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">{LANG == "en" ? "Service Detail" : "Détail du service"}</h1>
                < div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{
                            background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(/${service?.bigImage.fields.file.url}) center/cover no-repeat fixed`
                        }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 col-xl-8">
                                    <div className="hero-cap hero-cap2 pt-120">
                                        <h2>{LANG == "en" ? service?.name : service?.titleFrench}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="services-details-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="single-services section-padding2" style={{ paddingTop: '100px' }}>
                                    <div className="row">
                                        {service?.icons.length > 0 && (<div className="col-12">
                                            <div className="tab-content active" id="nav-tabContent">
                                                <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <div className="project-caption">
                                                        <div className="row">
                                                            {service?.icons.map((icon, index) => (
                                                                <li className="nav-item col-4" role="presentation" style={{ textAlign: 'center' }}>
                                                                    <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3" aria-selected="false" role="tab" tabIndex="-1">
                                                                        <img src={icon.fields.file.url} alt="" style={{ widt: '60px', height: '60px' }} />
                                                                        <h4 className="d-none d-lg-block">{icon.title}</h4>
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="container" style={{ paddingTop: '50px' }}>
                                        <div className="details-caption">
                                            {LANG == "en" ? service?.description : service?.descriptionFrench1}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Theme>
    );
}

export default Service;