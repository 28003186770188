export var DUMMY_DATA = {
    "project": [
        {
            "name": "Upgrade of the Digital Control and Monitoring System (SCADA) for the High Voltage Substations in the City of N'Djamena",
            "nameFrench": "Mise à niveau du système de contrôle commande numérique \"SCADA\" des postes Haute Tension de la vile N’Djamena",
            "description1": "Upgrade of the Digital Control and Monitoring System (SCADA) for the High Voltage Substations in the City of N'Djamena.",
            "descriptionFrench": "Mise à niveau du système de contrôle commande numérique \"SCADA\" des postes Haute Tension de la vile N’Djamena",
            "city": "Chad",
            "countryFrench": "République Du Tchad",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5LiyIX6KQAg9mkwPvgDj5N",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:39:24.848Z",
                        "updatedAt": "2024-10-29T07:39:24.848Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "05-24",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5LiyIX6KQAg9mkwPvgDj5N/eb6c47bc5163649dc6026c4b74f82722/05-24.jpg",
                            "details": {
                                "size": 200153,
                                "image": {
                                    "width": 1280,
                                    "height": 960
                                }
                            },
                            "fileName": "05-24.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Upgrade of the Digital Control and Monitoring System (SCADA) for the High Voltage Substations in the City of N'Djamena."
        },
        {
            "name": "Rehabilitation and Densification Works of the Medium Voltage (MV) and Low Voltage (LV) Network in the City of N'Djamena.",
            "nameFrench": "Travaux de Réhabilitation etDensification du Réseau Moyenne Tension (MT) et Basse Tension (BT) de la ville de N’Djamena",
            "description1": "Rehabilitation and Densification Works of the Medium Voltage (MV) and Low Voltage (LV) Network in the City of N'Djamena.",
            "descriptionFrench": "Travaux de Réhabilitation etDensification du Réseau Moyenne Tension (MT) et Basse Tension (BT) de la ville de N’Djamena",
            "city": "Chad",
            "countryFrench": "République Du Tchad",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5MOJrmeD1GBaAamzYUfIlT",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:37:30.240Z",
                        "updatedAt": "2024-10-29T07:37:30.240Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "04-24",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5MOJrmeD1GBaAamzYUfIlT/fcae809e979049774f5631c7a1ca18f5/04-24.jpg",
                            "details": {
                                "size": 86415,
                                "image": {
                                    "width": 700,
                                    "height": 387
                                }
                            },
                            "fileName": "04-24.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2NXvIpwwHO8XzzK3h1Psow",
                    "type": "Asset",
                    "createdAt": "2024-10-29T07:38:12.505Z",
                    "updatedAt": "2024-10-29T07:38:12.505Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Association internationale de développement",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2NXvIpwwHO8XzzK3h1Psow/85879fa54190d6485d8dfcbc04bb314a/images__2_.jpeg",
                        "details": {
                            "size": 14057,
                            "image": {
                                "width": 272,
                                "height": 185
                            }
                        },
                        "fileName": "images (2).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Association internationale de développement"
        },
        {
            "name": "Construction of a 7 MW Solar Photovoltaic Power Plant with Hybridization and BESS",
            "nameFrench": " Construction de la centrale solaire photovoltaïque 7MW avec hybridation et système BESS",
            "description1": " Construction de la centrale solaire photovoltaïque 7MW avec hybridation et système BESS",
            "descriptionFrench": " Construction de la centrale solaire photovoltaïque 7MW avec hybridation et système BESS",
            "city": "Mali",
            "countryFrench": "République du Mali",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "7u1jOoLF3vT8QRJQEmeDdE",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:34:54.343Z",
                        "updatedAt": "2024-10-29T07:34:54.343Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "08--22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/7u1jOoLF3vT8QRJQEmeDdE/f37ba8767e54ae94b03bd67c08d441e3/08--22.jpg",
                            "details": {
                                "size": 653581,
                                "image": {
                                    "width": 1200,
                                    "height": 680
                                }
                            },
                            "fileName": "08--22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1qYFQop17aMTgn6QfewvNS",
                    "type": "Asset",
                    "createdAt": "2024-10-29T07:36:11.512Z",
                    "updatedAt": "2024-10-29T07:36:11.512Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "IFC",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1qYFQop17aMTgn6QfewvNS/14f3422d716ad6ca6d5c7a8d97987445/IFC-Logo.png",
                        "details": {
                            "size": 72678,
                            "image": {
                                "width": 1000,
                                "height": 550
                            }
                        },
                        "fileName": "IFC-Logo.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "5YHeqWqSBs94KVoF8cwBEm",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:59:22.323Z",
                    "updatedAt": "2024-10-29T07:35:51.875Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ciments de l'Afrique",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/5YHeqWqSBs94KVoF8cwBEm/c6d70813bee1fc288e46518239ce9a66/logo-footer.png",
                        "details": {
                            "size": 9866,
                            "image": {
                                "width": 295,
                                "height": 100
                            }
                        },
                        "fileName": "logo-footer.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": " Construction de la centrale solaire photovoltaïque 7MW avec hybridation et système BESS"
        },
        {
            "name": "Extension Works for Access to Electricity in 12 Secondary Cities of Chad for Lot 2: Bongor, Moundou, Doba, Kroumra, and Sarh.",
            "nameFrench": "Travaux d'extension de l'acces à l'electricite dans les 12 Villes secondaires du Tchad pour lo Lot 2: Bongor, Moundou, Doba, Kroumra et Sarh",
            "description1": "Extension Works for Access to Electricity in 12 Secondary Cities of Chad for Lot 2: Bongor, Moundou, Doba, Kroumra, and Sarh.",
            "descriptionFrench": "Travaux d'extension de l'acces à l'electricite dans les 12 Villes secondaires du Tchad pour lo Lot 2: Bongor, Moundou, Doba, Kroumra et Sarh",
            "city": "Chad",
            "countryFrench": "République Tchad",
            "service": "Electricity",
            "serviceFrench": "Elect",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "uMr0OdvkjdGnm21TZxf43",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:31:11.686Z",
                        "updatedAt": "2024-10-29T07:31:11.686Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "02--24",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/uMr0OdvkjdGnm21TZxf43/69ed0d586326ab16359cff5995bc46d9/02--24.webp",
                            "details": {
                                "size": 48298,
                                "image": {
                                    "width": 1280,
                                    "height": 720
                                }
                            },
                            "fileName": "02--24.webp",
                            "contentType": "image/webp"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Extension Works for Access to Electricity in 12 Secondary Cities of Chad for Lot 2: Bongor, Moundou, Doba, Kroumra, and Sarh."
        },
        {
            "name": "Implementation of Rehabilitation and Densification Works for the Medium Voltage and Low Voltage Network in the City of N'Djamena.",
            "nameFrench": "Realisation des travaux de rehabilitation et densification du reseau moyenne tension et basse tension a la ville de N'Djamena ",
            "description1": "Implementation of Rehabilitation and Densification Works for the Medium Voltage and Low Voltage Network in the City of N'Djamena.",
            "descriptionFrench": "Implementation of Rehabilitation and Densification Works for the Medium Voltage and Low Voltage Network in the City of N'Djamena.",
            "city": "Chad",
            "countryFrench": " République Tchad ",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5KwfiMQujJIHO9WTGPUENi",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:29:44.807Z",
                        "updatedAt": "2024-10-29T07:29:44.807Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "01- 24",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5KwfiMQujJIHO9WTGPUENi/516249659a6d11df05e3a4e6e18db9bf/01-__24.jpg",
                            "details": {
                                "size": 169239,
                                "image": {
                                    "width": 713,
                                    "height": 475
                                }
                            },
                            "fileName": "01-  24.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Implementation of Rehabilitation and Densification Works for the Medium Voltage and Low Voltage Network in the City of N'Djamena."
        },
        {
            "name": "Supply and Installation of Solar Photovoltaic System Kits with storage in Bossangoa, Bozoum, Bouar, Berberati, and Sibut.",
            "nameFrench": " Fourniture et installation de kits de systèmes solaires photovoltaïques avec stockage (Bossangoa, Bozoum, Bouar, Berberati et Sibut) ",
            "description1": "Supply and Installation of Solar Photovoltaic System Kits with storage in Bossangoa, Bozoum, Bouar, Berberati, and Sibut.",
            "descriptionFrench": " Fourniture et installation de kits de systèmes solaires photovoltaïques avec stockage (Bossangoa, Bozoum, Bouar, Berberati et Sibut) ",
            "city": "Central African Republic",
            "countryFrench": "République centrafricaine",
            "service": "Photovoltaic",
            "serviceFrench": "Photovoltaique",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "3KmwM6ty7xgxzb1QW29GvJ",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:25:23.937Z",
                        "updatedAt": "2024-10-29T07:25:23.937Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "08--23",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/3KmwM6ty7xgxzb1QW29GvJ/b8a861024cb776aca5e303314f2386fd/08--23.jpeg",
                            "details": {
                                "size": 186762,
                                "image": {
                                    "width": 1248,
                                    "height": 701
                                }
                            },
                            "fileName": "08--23.jpeg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1iK6GgJi8v1mqjeHikS687",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:03:15.679Z",
                    "updatedAt": "2024-10-29T07:25:49.853Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 11,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enabel",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1iK6GgJi8v1mqjeHikS687/3cbd672cb16e31363b52e03d5c81f796/logo-enabel.png",
                        "details": {
                            "size": 13087,
                            "image": {
                                "width": 500,
                                "height": 171
                            }
                        },
                        "fileName": "logo-enabel.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1iK6GgJi8v1mqjeHikS687",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:03:15.679Z",
                    "updatedAt": "2024-10-29T07:25:49.853Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 11,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enabel",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1iK6GgJi8v1mqjeHikS687/3cbd672cb16e31363b52e03d5c81f796/logo-enabel.png",
                        "details": {
                            "size": 13087,
                            "image": {
                                "width": 500,
                                "height": 171
                            }
                        },
                        "fileName": "logo-enabel.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Supply and Installation of Solar Photovoltaic System Kits with storage in Bossangoa, Bozoum, Bouar, Berberati, and Sibut."
        },
        {
            "name": "Acquisition of Wooden Poles for the ENERCA Distribution Network in the City of Bangui.",
            "nameFrench": "Acquisition de poteaux bois pour le réseau de distribution de l'ENERCA dans la ville de Bangui",
            "description1": "Acquisition of Wooden Poles for the ENERCA Distribution Network in the City of Bangui.",
            "descriptionFrench": "Acquisition de poteaux bois pour le réseau de distribution de l'ENERCA dans la ville de Bangui",
            "city": "Central African Republic",
            "countryFrench": "République centrafricaine",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "4jegCrrzhwOpENQsRHPQ81",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:23:37.999Z",
                        "updatedAt": "2024-10-29T07:23:37.999Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "07-23",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/4jegCrrzhwOpENQsRHPQ81/6032998e803267d4d4cf55f1c7b9745c/07-23.jpg",
                            "details": {
                                "size": 177584,
                                "image": {
                                    "width": 1280,
                                    "height": 960
                                }
                            },
                            "fileName": "07-23.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Acquisition of Wooden Poles for the ENERCA Distribution Network in the City of Bangui."
        },
        {
            "name": "Acquisition of Connection Materials for the ENERCA Network in the City of Bangui: Connection Cables, Connection Supports, and Connecting Accessories",
            "nameFrench": "Acquisition de matériels de branchements au réseau de l’ENERCA dans la ville de Bangui : câbles de branchement , supports de branchement et accessoires de raccordement",
            "description1": "Acquisition of Connection Materials for the ENERCA Network in the City of Bangui: Connection Cables, Connection Supports, and Connecting Accessories",
            "descriptionFrench": "Acquisition de matériels de branchements au réseau de l’ENERCA dans la ville de Bangui : câbles de branchement , supports de branchement et accessoires de raccordement",
            "city": "Central African Republic",
            "countryFrench": "République centrafricaine",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1w5zLEKNpAVAQGaQJqPoKc",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:21:30.233Z",
                        "updatedAt": "2024-10-29T07:21:30.233Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "05-23",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1w5zLEKNpAVAQGaQJqPoKc/0f879886e713c3b06ccf83201925e748/05-23.jpg",
                            "details": {
                                "size": 213138,
                                "image": {
                                    "width": 720,
                                    "height": 1280
                                }
                            },
                            "fileName": "05-23.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Acquisition of Connection Materials for the ENERCA Network in the City of Bangui: Connection Cables, Connection Supports, and Connecting Accessories"
        },
        {
            "name": "The construction of approximately 32 boreholes equipped with solar pumping systems and elevated tanks, including the extension of distribution network systems and water points.",
            "nameFrench": "La construction d’environ (32) forages équipés de systèmes de pompage solaire et de châteaux élevés incluant l’extension des systèmes de réseau de distribution et des bornes fontaines",
            "description1": "The construction of approximately 32 boreholes equipped with solar pumping systems and elevated tanks, including the extension of distribution network systems and water points.",
            "descriptionFrench": "La construction d’environ (32) forages équipés de systèmes de pompage solaire et de châteaux élevés incluant l’extension des systèmes de réseau de distribution et des bornes fontaines",
            "city": "Togo",
            "countryFrench": "REPUBLIC OF TOGO",
            "service": "Food Drinking water",
            "serviceFrench": "Alimentation Eau potable",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "55mWUsQ5A0sZ46QBDBJzCj",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:18:00.180Z",
                        "updatedAt": "2024-10-29T07:18:00.180Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "04-23",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/55mWUsQ5A0sZ46QBDBJzCj/47c94b51f1b2001580dd48ec3be42e29/04-23.jpg",
                            "details": {
                                "size": 649173,
                                "image": {
                                    "width": 1600,
                                    "height": 900
                                }
                            },
                            "fileName": "04-23.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "La construction d’environ (32) forages équipés de systèmes de pompage solaire et de châteaux élevés incluant l’extension des systèmes de réseau de distribution et des bornes fontaines"
        },
        {
            "name": "Acquisition of Materials for the Extension and Connection of Water Points to the SODECA Distribution Network in Bambari",
            "nameFrench": "Acquisition de Matériels D’extension et de Raccordements des Bornes Fontaines sur le Réseau de Distribution de La SODECA à Bambari",
            "description1": "Acquisition of Materials for the Extension and Connection of Water Points to the SODECA Distribution Network in Bambari.",
            "descriptionFrench": "Acquisition de Matériels D’extension et de Raccordements des Bornes Fontaines sur le Réseau de Distribution de La SODECA à Bambari",
            "city": "Central African Republic",
            "countryFrench": "République centrafricaine",
            "service": "Food Drinking water",
            "serviceFrench": "Alimentation Eau potable",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "3aeP5Hx1ii9LkKYsNLx2L8",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:16:00.389Z",
                        "updatedAt": "2024-10-29T07:16:00.389Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "3-23",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/3aeP5Hx1ii9LkKYsNLx2L8/268561a400af7799447b4d5c1de0a45e/3-23.jpg",
                            "details": {
                                "size": 47330,
                                "image": {
                                    "width": 654,
                                    "height": 371
                                }
                            },
                            "fileName": "3-23.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "7wdY1yf2BUwGlZjaTwjgfL",
                    "type": "Asset",
                    "createdAt": "2024-10-29T06:52:07.014Z",
                    "updatedAt": "2024-10-29T06:52:07.014Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SODECA",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/7wdY1yf2BUwGlZjaTwjgfL/49d28f89305022ee124aaf30f1fdf97f/sodeca.1505996971.001.jpg",
                        "details": {
                            "size": 16660,
                            "image": {
                                "width": 300,
                                "height": 225
                            }
                        },
                        "fileName": "sodeca.1505996971.001.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Acquisition de Matériels D’extension et de Raccordements des Bornes Fontaines sur le Réseau de Distribution de La SODECA à Bambari"
        },
        {
            "name": "Supply and Installation of 4,120 solar photovoltaic street lights in Bangui, Central African Republic",
            "nameFrench": "Fourniture et Installation de 4120 lampadaires d'éclairage solaire a Bangui République Centrafricaine",
            "description1": "Supply and Installation of 4,120 solar photovoltaic street lights in Bangui, Central African Republic.",
            "descriptionFrench": "Fourniture et Installation de 4120 lampadaires d'éclairage solaire a Bangui République Centrafricaine",
            "city": "Central African Republic",
            "countryFrench": "République centrafricaine",
            "service": "Electricity",
            "serviceFrench": "Electricité ",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "PJ6kwHudPuLJDjYP9zURh",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:12:52.828Z",
                        "updatedAt": "2024-10-29T07:12:52.828Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "10-22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/PJ6kwHudPuLJDjYP9zURh/b65998484415b6d10616cc296a38c2ad/10-22.jpg",
                            "details": {
                                "size": 41527,
                                "image": {
                                    "width": 768,
                                    "height": 1024
                                }
                            },
                            "fileName": "10-22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "5keWw5si87naolYXxivJVu",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:28:14.059Z",
                    "updatedAt": "2024-10-10T09:28:14.059Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ministre d’Etat de l’Économie du Plan et de la Coopération internationale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/5keWw5si87naolYXxivJVu/fabdae83c8743e757653e1b51f28ab28/s-blob-v1-IMAGE-KsmRNbXrfEI.jpg",
                        "details": {
                            "size": 11964,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-KsmRNbXrfEI.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Ministre d’Etat de l’Économie du Plan et de la Coopération internationale"
        },
        {
            "name": "Supply, Installation, and Commissioning of Medium Voltage Capacitor Banks (15 kV and 30 kV) with Installation and Adaptation of Medium Voltage Cells for Batteries.",
            "nameFrench": "Fourniture, pose et mise en service de batteries de condensateurs moyenne tension (15 kV et 30 kV) avec installation et adaptation des cellules moyenne tension pour batteries",
            "description1": "Supply, Installation, and Commissioning of Medium Voltage Capacitor Banks (15 kV and 30 kV) with Installation and Adaptation of Medium Voltage Cells for Batteries.",
            "descriptionFrench": "Fourniture, pose et mise en service de batteries de condensateurs moyenne tension (15 kV et 30 kV) avec installation et adaptation des cellules moyenne tension pour batteries",
            "city": "Mali",
            "countryFrench": "MALI",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "3yCNQ1rEUhGW5UQ7MFTGOd",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:09:09.575Z",
                        "updatedAt": "2024-10-29T07:09:09.575Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "09-22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/3yCNQ1rEUhGW5UQ7MFTGOd/0bce6b312689c1530d36438eb25a8443/09-22.jpg",
                            "details": {
                                "size": 191887,
                                "image": {
                                    "width": 931,
                                    "height": 756
                                }
                            },
                            "fileName": "09-22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "72Z0vM0RWhq6mXtzQHbgl1",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:59:00.892Z",
                    "updatedAt": "2024-10-29T07:09:40.876Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "la Banque Ouest Africaine de Développement",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/72Z0vM0RWhq6mXtzQHbgl1/71ae1b4b915abbb084b44b1f4e427dd4/logo_boad.jpg",
                        "details": {
                            "size": 18510,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "logo_boad.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "CFC1VbtvyOflmqFd4Xuaw",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:01:30.493Z",
                    "updatedAt": "2024-10-29T07:10:11.037Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Energie du Mali",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/CFC1VbtvyOflmqFd4Xuaw/7450f9eba4b7b55f320798e843c54fc6/edm_848862243.jpg",
                        "details": {
                            "size": 18373,
                            "image": {
                                "width": 310,
                                "height": 450
                            }
                        },
                        "fileName": "edm_848862243.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Supply, Installation, and Commissioning of Medium Voltage Capacitor Banks (15 kV and 30 kV) with Installation and Adaptation of Medium Voltage Cells for Batteries."
        },
        {
            "name": "Construction of a 3 MW Solar Photovoltaic Power Plant at 'Kalam-Kalam'",
            "nameFrench": "Construction De La Centrale Solaire Photovoltaïque D’une Puissance De 3MWC A « Kalam-Kalam »",
            "description1": "Construction of a 3 MW Solar Photovoltaic Power Plant at 'Kalam-Kalam'",
            "descriptionFrench": "Construction De La Centrale Solaire Photovoltaïque D’une Puissance De 3MWC A « Kalam-Kalam »",
            "city": "Chad",
            "countryFrench": " République Tchad",
            "service": "Photovoltaic",
            "serviceFrench": "Photovoltaique",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1gWcKJC1cWnNt7IqvFSuTO",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:06:24.452Z",
                        "updatedAt": "2024-10-29T07:06:24.452Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "08--22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1gWcKJC1cWnNt7IqvFSuTO/0141957c2f7d6311901183e2a7e53a3e/08--22.jpg",
                            "details": {
                                "size": 653581,
                                "image": {
                                    "width": 1200,
                                    "height": 680
                                }
                            },
                            "fileName": "08--22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2HnbJZ13yV7ymlEjh46rik",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:23:49.758Z",
                    "updatedAt": "2024-10-10T09:23:49.758Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "IsDB Islamic Development Bank",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2HnbJZ13yV7ymlEjh46rik/9f00bb5f2aca0f04c2c633f72eda874b/Untitled-3.jpg",
                        "details": {
                            "size": 218271,
                            "image": {
                                "width": 2396,
                                "height": 1492
                            }
                        },
                        "fileName": "Untitled-3.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Construction De La Centrale Solaire Photovoltaïque D’une Puissance De 3MWC A « Kalam-Kalam »"
        },
        {
            "name": "Execution Studies, Supply, Construction Works, and Commissioning of MV and LV Electrical Networks in 41 Localities in the Kousseri Area of the Extreme North Region",
            "nameFrench": "Études D’exécution,  Fournitures,Exécution des Travaux De Construction Et La Mise En Service Des Réseaux Électriques HTA Et BT Dans 41 Localités De La Zone Kousseri De La Région De L’extrême Nord.",
            "description1": "Execution Studies, Supply, Construction Works, and Commissioning of MV and LV Electrical Networks in 41 Localities in the Kousseri Area of the Extreme North Region.",
            "descriptionFrench": "Études D’exécution,  Fournitures,Exécution des Travaux De Construction Et La Mise En Service Des Réseaux Électriques HTA Et BT Dans 41 Localités De La Zone Kousseri De La Région De L’extrême Nord.",
            "city": "Cameroon",
            "countryFrench": "République du Cameroun",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1AhxwdsFfLsZAssja5cIQo",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:04:05.351Z",
                        "updatedAt": "2024-10-29T07:04:05.351Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "04-22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1AhxwdsFfLsZAssja5cIQo/5c01b22cea848f0e8dc86ec369d5ec81/04-22.jpg",
                            "details": {
                                "size": 99194,
                                "image": {
                                    "width": 1000,
                                    "height": 750
                                }
                            },
                            "fileName": "04-22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "540rT6Mg2gj3FIrTS63OGi",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:16:42.484Z",
                    "updatedAt": "2024-10-10T09:16:42.484Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Agence d’Electrification Rurale (AER)",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/540rT6Mg2gj3FIrTS63OGi/ed28287e34de82a84d8a2a3c9f8e37db/images.jpeg",
                        "details": {
                            "size": 9385,
                            "image": {
                                "width": 215,
                                "height": 235
                            }
                        },
                        "fileName": "images.jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Execution Studies, Supply, Construction Works, and Commissioning of MV and LV Electrical Networks in 41 Localities in the Kousseri Area of the Extreme North Region"
        },
        {
            "name": "Partial Rehabilitation Works of the SODECA Distribution Network and Supply and Installation of Missing Parts in the City of Bangui.",
            "nameFrench": " Travaux De Réhabilitation Partielle Du Réseau De Distribution De La SODECA Et Fourniture Et Pose Des Pieces Manquantes Dans La Ville De Bangui",
            "description1": "Partial Rehabilitation Works of the SODECA Distribution Network and Supply and Installation of Missing Parts in the City of Bangui.\n",
            "descriptionFrench": " Travaux De Réhabilitation Partielle Du Réseau De Distribution De La SODECA Et Fourniture Et Pose Des Pieces Manquantes Dans La Ville De Bangui",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine ",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "jg0H2Mozf9pkppJUGxlg8",
                        "type": "Asset",
                        "createdAt": "2024-10-29T07:00:21.321Z",
                        "updatedAt": "2024-10-29T07:00:21.321Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "3-22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/jg0H2Mozf9pkppJUGxlg8/5e66445c57de008038c5afeca8db6c71/3-22.jpg",
                            "details": {
                                "size": 72965,
                                "image": {
                                    "width": 1080,
                                    "height": 1080
                                }
                            },
                            "fileName": "3-22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "6YoV3UHrutBUqNdSCL8sbh",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:27:02.933Z",
                    "updatedAt": "2024-10-29T07:01:54.928Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 6,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ministère en charge du Développement ",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/6YoV3UHrutBUqNdSCL8sbh/93c73a73e4913287148132ea818d2ea7/s-blob-v1-IMAGE-g4HfOLrPXL8.png",
                        "details": {
                            "size": 21133,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-g4HfOLrPXL8.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Partial Rehabilitation Works of the SODECA Distribution Network and Supply and Installation of Missing Parts in the City of Bangui."
        },
        {
            "name": "Acquisition of a 650 KVA Generator for Sodeca in Bangui, Central African Republic.",
            "nameFrench": "Acquisition d'un groupe électrogène de 650 KVA pour la Sodeca à Bangui en République Centrafricaine",
            "description1": "Acquisition of a 650 KVA Generator for Sodeca in Bangui, Central African Republic.",
            "descriptionFrench": "Acquisition d'un groupe électrogène de 650 KVA pour la Sodeca à Bangui en République Centrafricaine",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine",
            "service": "Procurement",
            "serviceFrench": "Procurement",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "6pwt6x35YczX2wkIw8jmUf",
                        "type": "Asset",
                        "createdAt": "2024-10-29T06:57:21.373Z",
                        "updatedAt": "2024-10-29T06:57:21.373Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "01-22",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/6pwt6x35YczX2wkIw8jmUf/d4f8ed0b33a54f52a924fd6ddb5b4840/01-22.jpg",
                            "details": {
                                "size": 236049,
                                "image": {
                                    "width": 1600,
                                    "height": 900
                                }
                            },
                            "fileName": "01-22.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Acquisition d'un groupe électrogène de 650 KVA pour la Sodeca à Bangui en République Centrafricaine"
        },
        {
            "name": "Procurement of twelve SE 44-1 Standard Tropicalized Current Transformers for the Classic Overhead Substation for Enerca in Bangui.",
            "nameFrench": "Achat de douze Transformateurs de courant de norme SE 44-1 Tropicalise pour poste aerien classique pour l'Enerca Bangui",
            "description1": "Procurement of twelve SE 44-1 Standard Tropicalized Current Transformers for the Classic Overhead Substation for Enerca in Bangui.",
            "descriptionFrench": "Achat de douze Transformateurs de courant de norme SE 44-1 Tropicalise pour poste aerien classique pour l'Enerca Bangui",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine",
            "service": "Procurement",
            "serviceFrench": "Procurement",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1VmLBneLRgoy0MnduA9kxj",
                        "type": "Asset",
                        "createdAt": "2024-10-29T06:54:33.611Z",
                        "updatedAt": "2024-10-29T06:54:33.611Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "01- 21",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1VmLBneLRgoy0MnduA9kxj/e7d5cc9b917bed6bafc1e331e5c905fd/01-_21.jpg",
                            "details": {
                                "size": 224604,
                                "image": {
                                    "width": 2048,
                                    "height": 1534
                                }
                            },
                            "fileName": "01- 21.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Procurement of twelve SE 44-1 Standard Tropicalized Current Transformers for the Classic Overhead Substation for Enerca in Bangui."
        },
        {
            "name": "Supply, Transport, and Installation of Pipes and Special Parts, as well as Civil Engineering for the Rehabilitation and Improvement Works of sistribution and treatment Water System project in the City of Bangui, Central African Republic.",
            "nameFrench": "Fourniture , Transport et Pose des Conduites et Pieces Spéciales et Travaux De Génie Civil pour le projet de rehabilitation et d'Amélioration du systeme d'adduction et de traitement d'Eau Potable de la ville de Bangui en RCA",
            "description1": "Supply, Transport, and Installation of Pipes and Special Parts, as well as Civil Engineering for the Rehabilitation and Improvement Works of sistribution and treatment Water System project in the City of Bangui, Central African Republic.",
            "descriptionFrench": "Supply, Transport, and Installation of Pipes and Special Parts, as well as Civil Engineering for the Rehabilitation and Improvement Works of sistribution and treatment Water System project in the City of Bangui, Central African Republic.",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "59uESr3NmkokwiiBW1bYaw",
                        "type": "Asset",
                        "createdAt": "2024-10-29T06:51:30.065Z",
                        "updatedAt": "2024-10-29T06:51:30.065Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "04-20",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/59uESr3NmkokwiiBW1bYaw/d6ad948b894135dede07a530ceb7db5f/04-20.jpg",
                            "details": {
                                "size": 2246848,
                                "image": {
                                    "width": 4032,
                                    "height": 3024
                                }
                            },
                            "fileName": "04-20.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "7wdY1yf2BUwGlZjaTwjgfL",
                    "type": "Asset",
                    "createdAt": "2024-10-29T06:52:07.014Z",
                    "updatedAt": "2024-10-29T06:52:07.014Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SODECA",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/7wdY1yf2BUwGlZjaTwjgfL/49d28f89305022ee124aaf30f1fdf97f/sodeca.1505996971.001.jpg",
                        "details": {
                            "size": 16660,
                            "image": {
                                "width": 300,
                                "height": 225
                            }
                        },
                        "fileName": "sodeca.1505996971.001.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Fourniture , Transport et Pose des Conduites et Pieces Spéciales et Travaux De Génie Civil pour le projet de rehabilitation et d'Amélioration du systeme d'adduction et de traitement d'Eau Potable de la ville de Bangui en RCA"
        },
        {
            "name": "Execution of Electrification Works in 18 Localities in the Adamaoua Region",
            "nameFrench": "Exécution des Travaux D'électrification dans 18 Localités Dans La Région de L’Adamaoua",
            "description1": "Exécution des Travaux D'électrification dans 18 Localités Dans La Région de L’Adamaoua",
            "descriptionFrench": "Exécution des Travaux D'électrification dans 18 Localités Dans La Région de L’Adamaoua",
            "city": "Cameroon",
            "countryFrench": "République du Cameroun ",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "36UeL1TwZXTUwubafxKXNQ",
                        "type": "Asset",
                        "createdAt": "2024-10-29T06:44:59.898Z",
                        "updatedAt": "2024-10-29T06:44:59.898Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "3-20",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/36UeL1TwZXTUwubafxKXNQ/c04015df4d83615e67a8bc574c6e95cc/3-20.jpg",
                            "details": {
                                "size": 85386,
                                "image": {
                                    "width": 923,
                                    "height": 464
                                }
                            },
                            "fileName": "3-20.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3IKeJsMYRhvDXKkxdPzBDD",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:56:26.425Z",
                    "updatedAt": "2024-10-17T15:56:26.425Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "BADEA logo",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3IKeJsMYRhvDXKkxdPzBDD/97379b5cc86ca32353074bacac19c40d/BADEA_logo.jpg",
                        "details": {
                            "size": 83135,
                            "image": {
                                "width": 1238,
                                "height": 648
                            }
                        },
                        "fileName": "BADEA_logo.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "540rT6Mg2gj3FIrTS63OGi",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:16:42.484Z",
                    "updatedAt": "2024-10-10T09:16:42.484Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Agence d’Electrification Rurale (AER)",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/540rT6Mg2gj3FIrTS63OGi/ed28287e34de82a84d8a2a3c9f8e37db/images.jpeg",
                        "details": {
                            "size": 9385,
                            "image": {
                                "width": 215,
                                "height": 235
                            }
                        },
                        "fileName": "images.jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "abbreviation": "Execution of Electrification Works in 18 Localities in the Adamaoua Region"
        },
        {
            "name": "Supply of Equipment for HTA/BT Substations, Project for the Improvement of Water and Electricity Services in Bangui.",
            "nameFrench": "Fourniture D'équipement Des Postes HTA/BT, Projet Amélioration des services d'eau et d'éléctricité BANGUI",
            "description1": "Supply of Equipment for HTA/BT Substations, Project for the Improvement of Water and Electricity Services in Bangui.",
            "descriptionFrench": "Fourniture D'équipement Des Postes HTA/BT, Projet Amélioration des services d'eau et d'éléctricité BANGUI",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine ",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5jNbvAZGyhNoNywpIp89ok",
                        "type": "Asset",
                        "createdAt": "2024-10-29T06:43:23.092Z",
                        "updatedAt": "2024-10-29T06:43:23.092Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "02-20",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5jNbvAZGyhNoNywpIp89ok/bd592878d2446f758cd50470012ff4b2/02-20.jpg",
                            "details": {
                                "size": 2380877,
                                "image": {
                                    "width": 4160,
                                    "height": 3120
                                }
                            },
                            "fileName": "02-20.jpg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Fourniture D'équipement Des Postes HTA/BT, Projet Amélioration des services d'eau et d'éléctricité BANGUI"
        },
        {
            "name": "Acquisition, Assembly, and Installation of Solar Photovoltaic Street Lights in the Cities of Bangui and Bangassou in the Central African Republic",
            "nameFrench": "Acquisition, Montage Et Installation Des Lampadaires D’eclairage Public En Panneaux Solaires Photovoltaïques Dans Les Villes De Bangui Et Bangassou En Republique Centrafricaine",
            "description1": "Acquisition, Assembly, and Installation of Solar Photovoltaic Street Lights in the Cities of Bangui and Bangassou in the Central African Republic",
            "descriptionFrench": "Acquisition, Montage Et Installation Des Lampadaires D’eclairage Public En Panneaux Solaires Photovoltaïques Dans Les Villes De Bangui Et Bangassou En Republique Centrafricaine",
            "city": "Central African Republic",
            "countryFrench": " République Centrfricaine",
            "service": "Photovoltaics",
            "serviceFrench": "Photovoltaique",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "52dO8mTRj29lRTAclfOUOe",
                        "type": "Asset",
                        "createdAt": "2024-10-23T15:44:05.564Z",
                        "updatedAt": "2024-10-23T15:44:05.564Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "1-20",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/52dO8mTRj29lRTAclfOUOe/b5bce788388a41f7a968300f3c0aae8c/1-20.webp",
                            "details": {
                                "size": 31490,
                                "image": {
                                    "width": 800,
                                    "height": 500
                                }
                            },
                            "fileName": "1-20.webp",
                            "contentType": "image/webp"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4jcflk06fJgkB9MBGZxLbu",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:44:25.624Z",
                    "updatedAt": "2024-10-23T15:44:25.624Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "UNOPS",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4jcflk06fJgkB9MBGZxLbu/52d73790fca05235e346b1a8c57b0bfe/images.png",
                        "details": {
                            "size": 12044,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4jcflk06fJgkB9MBGZxLbu",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:44:25.624Z",
                    "updatedAt": "2024-10-23T15:44:25.624Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "UNOPS",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4jcflk06fJgkB9MBGZxLbu/52d73790fca05235e346b1a8c57b0bfe/images.png",
                        "details": {
                            "size": 12044,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Acquisition, assembly, and installation of solar street lights in Bangui and Bangassou, CAR"
        },
        {
            "name": "Construction of a 10 MW Thermal Power Plant in the Central African Republic",
            "nameFrench": "Construction d'une Central thermique 10 Mw en République Centrfricaine",
            "description1": "Construction of a 10 MW Thermal Power Plant in the Central African Republic",
            "descriptionFrench": "Construction d'une Central thermique 10 Mw en République Centrfricaine ",
            "city": "Central African Republic",
            "countryFrench": "République Centrfricaine",
            "service": "Electricity",
            "serviceFrench": "Electricité",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5ZGxITPW4wBk7am6ntEVU6",
                        "type": "Asset",
                        "createdAt": "2024-10-22T14:29:19.407Z",
                        "updatedAt": "2024-10-22T14:29:19.407Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "1-18- centrale",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5ZGxITPW4wBk7am6ntEVU6/1484597751667ee81dd916424e3d3580/1-18-_centrale.jpeg",
                            "details": {
                                "size": 161363,
                                "image": {
                                    "width": 1080,
                                    "height": 720
                                }
                            },
                            "fileName": "1-18- centrale.jpeg",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Construction of a 10 MW Thermal Power Plant in CAR."
        },
        {
            "name": "Supply and Installation of 4,120 solar photovoltaic street lights in Bangui, Central African Republic",
            "nameFrench": "Fourniture et Installation de 4120 lampadaires d'éclairage solaire a Bangui République Centrafricaine",
            "description1": "Supply and Installation of 4,120 solar photovoltaic street lights in Bangui, Central African Republic",
            "descriptionFrench": "Fourniture et Installation de 4120 lampadaires d'éclairage solaire a Bangui République Centrafricaine",
            "city": "Central African Republic",
            "countryFrench": "République Centrfricaine",
            "service": "Photovoltaique",
            "serviceFrench": "Photovoltaique",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "i3QH9hebRWSTOULVVYiRU",
                        "type": "Asset",
                        "createdAt": "2024-10-22T14:31:50.194Z",
                        "updatedAt": "2024-10-22T14:31:50.194Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 3,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "1-18-eclairage",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/i3QH9hebRWSTOULVVYiRU/64b1379f5473159a3dfcdda199174e7d/1-18-eclairage.JPG",
                            "details": {
                                "size": 5280443,
                                "image": {
                                    "width": 5184,
                                    "height": 3456
                                }
                            },
                            "fileName": "1-18-eclairage.JPG",
                            "contentType": "image/jpeg"
                        }
                    }
                }
            ],
            "funder": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            },
            "client": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            },
            "abbreviation": "Supply and installation of 4,120 solar street lights in Bangui, CAR"
        }
    ],
    "services": [
        {
            "name": "Electricity",
            "titleFrench": "Électricité",
            "description": "TRAGEDEL is a global leader in electricity production, distribution, and transmission. We specialize in industrial power line networks, factory cabling, and HT, MT, and BT lines (overhead and underground). Our team also installs and renovates public lighting systems with a focus on safety and reliability, ensuring efficient energy infrastructure for all your needs.",
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1jObynkwii1PxiTNQQGPGf",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:20:23.157Z",
                    "updatedAt": "2024-10-23T15:20:23.157Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "DR-PO-hero-752x580-1",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1jObynkwii1PxiTNQQGPGf/be6c6cff051bcf5ab2088edf5f39603b/DR-PO-hero-752x580-1.jpg",
                        "details": {
                            "size": 89782,
                            "image": {
                                "width": 752,
                                "height": 580
                            }
                        },
                        "fileName": "DR-PO-hero-752x580-1.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "bigImage": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1jObynkwii1PxiTNQQGPGf",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:20:23.157Z",
                    "updatedAt": "2024-10-23T15:20:23.157Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "DR-PO-hero-752x580-1",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1jObynkwii1PxiTNQQGPGf/be6c6cff051bcf5ab2088edf5f39603b/DR-PO-hero-752x580-1.jpg",
                        "details": {
                            "size": 89782,
                            "image": {
                                "width": 752,
                                "height": 580
                            }
                        },
                        "fileName": "DR-PO-hero-752x580-1.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "icons": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5zsmGYBX5CzFz9BusQNgms",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:07.491Z",
                        "updatedAt": "2024-10-08T12:58:07.491Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Comprehensive Solutions in Electricity Infrastructure",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5zsmGYBX5CzFz9BusQNgms/5cf652001586594bdf00fa58c48899ff/energy__1_.png",
                            "details": {
                                "size": 15065,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy (1).png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "75Q4au5D3jlDvsrTetNqk0",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:08.276Z",
                        "updatedAt": "2024-10-08T12:58:08.276Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Expertise in Power Line Networks and Cabling",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/75Q4au5D3jlDvsrTetNqk0/956922021915d5c72c9cf9252b810be7/energy_1.png",
                            "details": {
                                "size": 9989,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy_1.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1occeT1KN5H3Uh3bpDTTHN",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:09.028Z",
                        "updatedAt": "2024-10-08T12:58:09.028Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Ensuring Safe and Reliable Energy Supply",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1occeT1KN5H3Uh3bpDTTHN/74de51191be5f5b43ee8e80e5fbec178/energy_2.png",
                            "details": {
                                "size": 17723,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy_2.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "descriptionFrench1": "TRAGEDEL est un leader mondial dans la production, distribution et transmission d’électricité. Nous sommes spécialisés dans les réseaux électriques industriels, le câblage d’usines et les lignes HT, MT et BT (aériennes et souterraines). Notre équipe installe et rénove également les systèmes d'éclairage public en mettant l'accent sur la sécurité et la fiabilité, garantissant une infrastructure énergétique efficace pour tous vos besoins."
        },
        {
            "name": "Construction",
            "titleFrench": "Construction",
            "description": "TRAGEDEL specializes in construction, driven by an experienced team capable of meeting every challenge. From planning to coordination and execution, we continuously strive for excellence, innovation, and customer satisfaction. We are committed to delivering exceptional results that meet all your needs, whether it's industrial construction or specialized projects.\n",
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3cuo8W35fqT6VoOa9kI1DE",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:18:41.609Z",
                    "updatedAt": "2024-10-23T15:18:41.609Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Construction-site-iStock-1267010934",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3cuo8W35fqT6VoOa9kI1DE/00bb61e983261b92ada4fbf6ac7fb37f/Construction-site-iStock-1267010934.jpg",
                        "details": {
                            "size": 382829,
                            "image": {
                                "width": 1921,
                                "height": 1282
                            }
                        },
                        "fileName": "Construction-site-iStock-1267010934.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "bigImage": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3cuo8W35fqT6VoOa9kI1DE",
                    "type": "Asset",
                    "createdAt": "2024-10-23T15:18:41.609Z",
                    "updatedAt": "2024-10-23T15:18:41.609Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Construction-site-iStock-1267010934",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3cuo8W35fqT6VoOa9kI1DE/00bb61e983261b92ada4fbf6ac7fb37f/Construction-site-iStock-1267010934.jpg",
                        "details": {
                            "size": 382829,
                            "image": {
                                "width": 1921,
                                "height": 1282
                            }
                        },
                        "fileName": "Construction-site-iStock-1267010934.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "icons": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1X2m2xZzvfFKr7MImBgyPh",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:02.464Z",
                        "updatedAt": "2024-10-08T12:58:02.464Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Expertise in Industrial Construction",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1X2m2xZzvfFKr7MImBgyPh/c1f98f684d30f307a8b9efa145868912/c3.png",
                            "details": {
                                "size": 10949,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "c3.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "1eXhraG8VBPyr2tWDe6oOx",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:03.224Z",
                        "updatedAt": "2024-10-08T12:58:03.224Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Commitment to Innovation and Satisfaction",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/1eXhraG8VBPyr2tWDe6oOx/e6e949ec37f0894ac7f094f93e90d586/c2.png",
                            "details": {
                                "size": 11835,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "c2.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "5VlUATzXYsSx7n7JyyuXiJ",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:04.031Z",
                        "updatedAt": "2024-10-08T12:58:04.031Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Energy Performance and Reducing Costs",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/5VlUATzXYsSx7n7JyyuXiJ/adbab9a9de02748bb4dfdccff7913e4f/energy.png",
                            "details": {
                                "size": 25115,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "descriptionFrench1": "TRAGEDEL est spécialisé dans la construction, animé par une équipe expérimentée capable de relever tous les défis. De la planification à la coordination et à l’exécution, nous recherchons continuellement l’excellence, l’innovation et la satisfaction du client. Nous nous engageons à livrer des résultats exceptionnels qui répondent à tous vos besoins, qu'il s'agisse de construction industrielle ou de projets spécialisés."
        },
        {
            "name": "Renewable Energy",
            "titleFrench": "Énergie renouvelable",
            "description": "TRAGEDEL invests in the production of electricity through renewable energy, supported by an experienced team capable of overcoming every challenge. We focus on optimizing your energy performance to meet all your needs. Specializing in the installation of photovoltaic solar power plants.",
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3CjDfQNFzZt17EWzdp5oHb",
                    "type": "Asset",
                    "createdAt": "2024-10-12T08:00:19.328Z",
                    "updatedAt": "2024-10-12T08:00:19.328Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "WhatsApp Image 2024-10-12 at 12.59.43 7a43da67",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3CjDfQNFzZt17EWzdp5oHb/2f73223e18ce0b1fcba58ae80e3620c9/WhatsApp_Image_2024-10-12_at_12.59.43_7a43da67.jpg",
                        "details": {
                            "size": 444220,
                            "image": {
                                "width": 1280,
                                "height": 853
                            }
                        },
                        "fileName": "WhatsApp Image 2024-10-12 at 12.59.43_7a43da67.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "bigImage": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3nzSyTZoALhaNw1uv4guUg",
                    "type": "Asset",
                    "createdAt": "2024-10-12T08:00:42.468Z",
                    "updatedAt": "2024-10-12T08:00:42.468Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "WhatsApp Image 2024-10-12 at 12.58.56 99a102af",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3nzSyTZoALhaNw1uv4guUg/973a9d46aebeb142bde9735bbb25dcaf/WhatsApp_Image_2024-10-12_at_12.58.56_99a102af.jpg",
                        "details": {
                            "size": 693251,
                            "image": {
                                "width": 1280,
                                "height": 852
                            }
                        },
                        "fileName": "WhatsApp Image 2024-10-12 at 12.58.56_99a102af.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "icons": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "6jhhhEIohrRD7neZumRrgq",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:04.847Z",
                        "updatedAt": "2024-10-08T12:58:04.847Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Investing in Renewable Energy Solutions",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/6jhhhEIohrRD7neZumRrgq/a5b61f70705a30b01aa2db0940b91859/energy_1.png",
                            "details": {
                                "size": 9989,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy_1.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "4vRK9R56ZFclZtQ5U77h1U",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:05.824Z",
                        "updatedAt": "2024-10-08T12:58:05.824Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Energy Performance with Solar Power",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/4vRK9R56ZFclZtQ5U77h1U/889df33e51a734fb00860ab67819c9c3/energy__1_.png",
                            "details": {
                                "size": 15065,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy (1).png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "2V29loluoNaEzXlDd4ma5g",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:06.635Z",
                        "updatedAt": "2024-10-08T12:58:06.635Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Innovative Projects for a Sustainable Future",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/2V29loluoNaEzXlDd4ma5g/0006b3e8e2abe244d513d32df79e427a/energy_2.png",
                            "details": {
                                "size": 17723,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "energy_2.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "descriptionFrench1": "TRAGEDEL investit dans la production d'électricité à partir d'énergies renouvelables, appuyée par une équipe expérimentée capable de relever tous les défis. Nous nous concentrons sur l’optimisation de votre performance énergétique pour répondre à tous vos besoins. Spécialisée dans l'installation de centrales solaires photovoltaïques."
        },
        {
            "name": "Water Treatment",
            "titleFrench": "Traitement de l'eau",
            "description": "TRAGEDEL supports global efforts to improve access to clean water. We specialize in wastewater management for urban and industrial areas, using advanced treatment methods that meet international standards. Our solutions optimize water storage, distribution, and management, ensuring efficiency, sustainability, and safety in every project.",
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1WDUyGx94AOFof4TYXpkpE",
                    "type": "Asset",
                    "createdAt": "2024-10-12T08:01:36.107Z",
                    "updatedAt": "2024-10-12T08:01:36.107Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "WhatsApp Image 2024-10-12 at 13.00.27 c5fd703e",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1WDUyGx94AOFof4TYXpkpE/9dff7cb320624d19ddbb8412c202d804/WhatsApp_Image_2024-10-12_at_13.00.27_c5fd703e.jpg",
                        "details": {
                            "size": 589394,
                            "image": {
                                "width": 1280,
                                "height": 911
                            }
                        },
                        "fileName": "WhatsApp Image 2024-10-12 at 13.00.27_c5fd703e.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "bigImage": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1WDUyGx94AOFof4TYXpkpE",
                    "type": "Asset",
                    "createdAt": "2024-10-12T08:01:36.107Z",
                    "updatedAt": "2024-10-12T08:01:36.107Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "WhatsApp Image 2024-10-12 at 13.00.27 c5fd703e",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1WDUyGx94AOFof4TYXpkpE/9dff7cb320624d19ddbb8412c202d804/WhatsApp_Image_2024-10-12_at_13.00.27_c5fd703e.jpg",
                        "details": {
                            "size": 589394,
                            "image": {
                                "width": 1280,
                                "height": 911
                            }
                        },
                        "fileName": "WhatsApp Image 2024-10-12 at 13.00.27_c5fd703e.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            },
            "icons": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "457R850vsLVzx8UoLtlVlP",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:09.842Z",
                        "updatedAt": "2024-10-08T12:58:09.842Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Enhancing Global Access to Clean Drinking Water",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/457R850vsLVzx8UoLtlVlP/b810b0bf7ddb0a32d967047fff48ae4b/drop.png",
                            "details": {
                                "size": 17888,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "drop.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "2DxBV7fjmU88NkBBnD4Lpn",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:10.669Z",
                        "updatedAt": "2024-10-08T12:58:10.669Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Expert Wastewater Management and Treatment Solutions",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/2DxBV7fjmU88NkBBnD4Lpn/ed5b6dfcc1fe9f7598c4e97ae771eb27/wind.png",
                            "details": {
                                "size": 17858,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "wind.png",
                            "contentType": "image/png"
                        }
                    }
                },
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "2DT2CQq9odtHnDm6gk78gB",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:11.445Z",
                        "updatedAt": "2024-10-08T12:58:11.445Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "Sustainable Strategies for Water Supply and Distribution",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/2DT2CQq9odtHnDm6gk78gB/8c89f441e5eaef308cc86f07be9ac0d3/wind1.png",
                            "details": {
                                "size": 16343,
                                "image": {
                                    "width": 512,
                                    "height": 512
                                }
                            },
                            "fileName": "wind1.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "descriptionFrench1": "TRAGEDEL contribue aux efforts mondiaux pour améliorer l'accès à l'eau potable. Nous sommes spécialisés dans la gestion des eaux usées en milieu urbain et industriel, en utilisant des méthodes de traitement avancées conformes aux normes internationales. Nos solutions optimisent la gestion, le stockage et la distribution de l'eau, garantissant efficacité, durabilité et sécurité."
        }
    ],
    "clients": [
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1YwuStTy5hF8XzwfRU6rhi",
                    "type": "Asset",
                    "createdAt": "2024-10-20T15:10:04.199Z",
                    "updatedAt": "2024-10-22T14:57:09.104Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 7,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "steg",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1YwuStTy5hF8XzwfRU6rhi/4ef51fb8a2456c7611342eb499cca12d/societe-tunisienne-delectricite-et-de-gaz.png",
                        "details": {
                            "size": 20602,
                            "image": {
                                "width": 505,
                                "height": 304
                            }
                        },
                        "fileName": "societe-tunisienne-delectricite-et-de-gaz.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "112o1EdngdS6rv7XGW83WR",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:04:02.554Z",
                    "updatedAt": "2024-10-17T16:16:42.846Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "15",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/112o1EdngdS6rv7XGW83WR/4f19fb9452f4a006fbd2ae0c6536b504/logo-afd.png",
                        "details": {
                            "size": 25394,
                            "image": {
                                "width": 500,
                                "height": 208
                            }
                        },
                        "fileName": "logo-afd.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "1iK6GgJi8v1mqjeHikS687",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:03:15.679Z",
                    "updatedAt": "2024-10-29T07:25:49.853Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 11,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enabel",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/1iK6GgJi8v1mqjeHikS687/3cbd672cb16e31363b52e03d5c81f796/logo-enabel.png",
                        "details": {
                            "size": 13087,
                            "image": {
                                "width": 500,
                                "height": 171
                            }
                        },
                        "fileName": "logo-enabel.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "5keWw5si87naolYXxivJVu",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:28:14.059Z",
                    "updatedAt": "2024-10-10T09:28:14.059Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ministre d’Etat de l’Économie du Plan et de la Coopération internationale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/5keWw5si87naolYXxivJVu/fabdae83c8743e757653e1b51f28ab28/s-blob-v1-IMAGE-KsmRNbXrfEI.jpg",
                        "details": {
                            "size": 11964,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-KsmRNbXrfEI.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2HnbJZ13yV7ymlEjh46rik",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:23:49.758Z",
                    "updatedAt": "2024-10-10T09:23:49.758Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "IsDB Islamic Development Bank",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2HnbJZ13yV7ymlEjh46rik/9f00bb5f2aca0f04c2c633f72eda874b/Untitled-3.jpg",
                        "details": {
                            "size": 218271,
                            "image": {
                                "width": 2396,
                                "height": 1492
                            }
                        },
                        "fileName": "Untitled-3.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "540rT6Mg2gj3FIrTS63OGi",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:16:42.484Z",
                    "updatedAt": "2024-10-10T09:16:42.484Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Agence d’Electrification Rurale (AER)",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/540rT6Mg2gj3FIrTS63OGi/ed28287e34de82a84d8a2a3c9f8e37db/images.jpeg",
                        "details": {
                            "size": 9385,
                            "image": {
                                "width": 215,
                                "height": 235
                            }
                        },
                        "fileName": "images.jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "CFC1VbtvyOflmqFd4Xuaw",
                    "type": "Asset",
                    "createdAt": "2024-10-17T16:01:30.493Z",
                    "updatedAt": "2024-10-29T07:10:11.037Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Energie du Mali",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/CFC1VbtvyOflmqFd4Xuaw/7450f9eba4b7b55f320798e843c54fc6/edm_848862243.jpg",
                        "details": {
                            "size": 18373,
                            "image": {
                                "width": 310,
                                "height": 450
                            }
                        },
                        "fileName": "edm_848862243.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "6YoV3UHrutBUqNdSCL8sbh",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:27:02.933Z",
                    "updatedAt": "2024-10-29T07:01:54.928Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 6,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ministère en charge du Développement ",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/6YoV3UHrutBUqNdSCL8sbh/93c73a73e4913287148132ea818d2ea7/s-blob-v1-IMAGE-g4HfOLrPXL8.png",
                        "details": {
                            "size": 21133,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-g4HfOLrPXL8.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2dtnicOqJgz5qwTsSOEHgY",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:24:16.188Z",
                    "updatedAt": "2024-10-10T09:24:16.188Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "SOCIETE NATIONALE D’ELECTRICITE",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2dtnicOqJgz5qwTsSOEHgY/7eec4f8bf9b412d218ac5855b4349abc/images__1_.jpeg",
                        "details": {
                            "size": 4490,
                            "image": {
                                "width": 225,
                                "height": 225
                            }
                        },
                        "fileName": "images (1).jpeg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4QS34h7yd8BS73hDUmKVaX",
                    "type": "Asset",
                    "createdAt": "2024-10-10T09:33:22.118Z",
                    "updatedAt": "2024-10-10T09:33:22.118Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 4,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Enerca",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4QS34h7yd8BS73hDUmKVaX/267955b752d1cc3a13c60b33e1333063/s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "details": {
                            "size": 6333,
                            "image": {
                                "width": 259,
                                "height": 194
                            }
                        },
                        "fileName": "s-blob-v1-IMAGE-ZyZO_8TW15U.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "5YHeqWqSBs94KVoF8cwBEm",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:59:22.323Z",
                    "updatedAt": "2024-10-29T07:35:51.875Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Ciments de l'Afrique",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/5YHeqWqSBs94KVoF8cwBEm/c6d70813bee1fc288e46518239ce9a66/logo-footer.png",
                        "details": {
                            "size": 9866,
                            "image": {
                                "width": 295,
                                "height": 100
                            }
                        },
                        "fileName": "logo-footer.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "72Z0vM0RWhq6mXtzQHbgl1",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:59:00.892Z",
                    "updatedAt": "2024-10-29T07:09:40.876Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 5,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "la Banque Ouest Africaine de Développement",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/72Z0vM0RWhq6mXtzQHbgl1/71ae1b4b915abbb084b44b1f4e427dd4/logo_boad.jpg",
                        "details": {
                            "size": 18510,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "logo_boad.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "2GpK6GQiN97TftBjvmgZTW",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:58:35.044Z",
                    "updatedAt": "2024-10-17T15:58:35.044Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "BAD-BON",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/2GpK6GQiN97TftBjvmgZTW/4413065de448c815a4f5bee0395e300e/BAD-BON.jpg",
                        "details": {
                            "size": 37696,
                            "image": {
                                "width": 296,
                                "height": 292
                            }
                        },
                        "fileName": "BAD-BON.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3IKeJsMYRhvDXKkxdPzBDD",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:56:26.425Z",
                    "updatedAt": "2024-10-17T15:56:26.425Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 3,
                    "revision": 1,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "BADEA logo",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3IKeJsMYRhvDXKkxdPzBDD/97379b5cc86ca32353074bacac19c40d/BADEA_logo.jpg",
                        "details": {
                            "size": 83135,
                            "image": {
                                "width": 1238,
                                "height": 648
                            }
                        },
                        "fileName": "BADEA_logo.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "4OZvPCAQToyYssJXtlAncC",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:33.333Z",
                    "updatedAt": "2024-10-29T06:49:18.571Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 10,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Le Groupe de la Banque mondiale",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/4OZvPCAQToyYssJXtlAncC/6e38a3207ef69ad11fb9532178e42025/Banque-mondiale.jpg",
                        "details": {
                            "size": 49953,
                            "image": {
                                "width": 400,
                                "height": 400
                            }
                        },
                        "fileName": "Banque-mondiale.jpg",
                        "contentType": "image/jpeg"
                    }
                }
            }
        },
        {
            "image": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "26VnZ63vxM0SLyRDyYYzdh",
                    "type": "Asset",
                    "createdAt": "2024-10-17T15:54:58.238Z",
                    "updatedAt": "2024-10-23T15:31:36.003Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 13,
                    "revision": 4,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "Saudi Fund for Development",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/26VnZ63vxM0SLyRDyYYzdh/ffa2823edb6f67e004360bfbeb0d1e0d/Saudi_Fund_for_Development_Logo.svg.png",
                        "details": {
                            "size": 102223,
                            "image": {
                                "width": 1200,
                                "height": 395
                            }
                        },
                        "fileName": "Saudi_Fund_for_Development_Logo.svg.png",
                        "contentType": "image/png"
                    }
                }
            }
        }
    ],
    "news": [
        {
            "title": "ISO 9001 : Quality management systems",
            "titleFrench": "ISO 9001 : Systèmes de Gestion de la qualité",
            "description": "Management and execution of turnkey projects in: - Water management infrastructure (boreholes, supply networks, pumping stations, treatment stations - Infrastructures and installations for production and distribution of electricity (LV/MV Distribution networks, Thermal and photovoltaic power plant, Substations) - Industrial installations.",
            "descriptionFrench": "Gestion et réalisation de projets clé en main en : - Infrastructures de gestion de l'eau (forages, réseaux d'adduction, stations de pompage, stations de traitement - Infrastructures et installations de production et de distribution d'électricité (Réseaux de distribution BT/MT, Centrale thermique et photovoltaïque, Sous-stations) - Industriel installations.",
            "category": "Certification",
            "categoryFrench": "Attestation",
            "date": "2024-09-20",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "6m5vsLX5ldpEfwYFAVqv91",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:28.039Z",
                        "updatedAt": "2024-10-08T12:58:28.039Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "certificate1",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/6m5vsLX5ldpEfwYFAVqv91/d9f2ff1ed200d4d97211911b4ff9c938/certificate1.png",
                            "details": {
                                "size": 130328,
                                "image": {
                                    "width": 525,
                                    "height": 829
                                }
                            },
                            "fileName": "certificate1.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "icon": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "68z8nyqUZQWZCrIaSxTAP7",
                    "type": "Asset",
                    "createdAt": "2024-10-10T08:20:24.222Z",
                    "updatedAt": "2024-10-17T14:48:34.824Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 7,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "bell",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/68z8nyqUZQWZCrIaSxTAP7/1c15e22f5a83f6126c342429acfa5dcf/bell.png",
                        "details": {
                            "size": 954,
                            "image": {
                                "width": 64,
                                "height": 64
                            }
                        },
                        "fileName": "bell.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "title": "ISO 14001: Environmental management systems",
            "titleFrench": "ISO 14001: Systèmes de gestion environnementale",
            "description": "Management and execution of turnkey projects in: - Water management infrastructure (boreholes, supply networks, pumping stations, treatment stations - Infrastructures and installations for production and distribution of electricity (LV/MV Distribution networks, Thermal and photovoltaic power plant, Substations) - Industrial installations ",
            "descriptionFrench": "Gestion et réalisation de projets clé en main en : - Infrastructures de gestion de l'eau (forages, réseaux d'adduction, stations de pompage, stations de traitement - Infrastructures et installations de production et de distribution d'électricité (Réseaux de distribution BT/MT, Centrale thermique et photovoltaïque, Sous-stations) - Industriel installation\n",
            "category": "Certification",
            "categoryFrench": "Attestation",
            "date": "2024-09-20",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "53U3WnxYdwncugMv7JjXCZ",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:29.623Z",
                        "updatedAt": "2024-10-08T12:58:29.623Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "certificate3",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/53U3WnxYdwncugMv7JjXCZ/eb6b837ecf3c105b135eaf0c6d44bf5f/certificate3.png",
                            "details": {
                                "size": 130681,
                                "image": {
                                    "width": 526,
                                    "height": 832
                                }
                            },
                            "fileName": "certificate3.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "icon": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "5D5cow4551EUjL4aqAiOYj",
                    "type": "Asset",
                    "createdAt": "2024-10-10T08:19:34.900Z",
                    "updatedAt": "2024-10-17T14:48:53.595Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 7,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "tree",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/5D5cow4551EUjL4aqAiOYj/d2dd497ca5cb4366f39e1d9545ef8f87/tree.png",
                        "details": {
                            "size": 4912,
                            "image": {
                                "width": 64,
                                "height": 64
                            }
                        },
                        "fileName": "tree.png",
                        "contentType": "image/png"
                    }
                }
            }
        },
        {
            "title": "Iso 45001 : Health and safety (OH&S) management system",
            "titleFrench": "Iso 45001 : système de gestion de la santé et de la sécurité (SST)",
            "description": "Management and execution of turnkey projects in: - Water management infrastructure (boreholes, supply networks, pumping stations, treatment stations - Infrastructures and installations for production and distribution of electricity (LV/MV Distribution networks, Thermal and photovoltaic power plant, Substations) - Industrial installations ",
            "descriptionFrench": "Gestion et réalisation de projets clé en main en : - Infrastructures de gestion de l'eau (forages, réseaux d'adduction, stations de pompage, stations de traitement - Infrastructures et installations de production et de distribution d'électricité (Réseaux de distribution BT/MT, Centrale thermique et photovoltaïque, Sous-stations) - Industriel installation",
            "category": "Certification",
            "categoryFrench": "Attestation",
            "date": "2024-09-20 ",
            "images": [
                {
                    "metadata": {
                        "tags": [],
                        "concepts": []
                    },
                    "sys": {
                        "space": {
                            "sys": {
                                "type": "Link",
                                "linkType": "Space",
                                "id": "41chegrmhit0"
                            }
                        },
                        "id": "2lc7LR8Hgb7yBUYIe9fgP4",
                        "type": "Asset",
                        "createdAt": "2024-10-08T12:58:28.835Z",
                        "updatedAt": "2024-10-08T12:58:28.835Z",
                        "environment": {
                            "sys": {
                                "id": "master",
                                "type": "Link",
                                "linkType": "Environment"
                            }
                        },
                        "publishedVersion": 4,
                        "revision": 1,
                        "locale": "en-US"
                    },
                    "fields": {
                        "title": "certificate2",
                        "description": "",
                        "file": {
                            "url": "//images.ctfassets.net/41chegrmhit0/2lc7LR8Hgb7yBUYIe9fgP4/e667fb036930494a8025ec0dd8404da0/certificate2.png",
                            "details": {
                                "size": 112243,
                                "image": {
                                    "width": 478,
                                    "height": 758
                                }
                            },
                            "fileName": "certificate2.png",
                            "contentType": "image/png"
                        }
                    }
                }
            ],
            "icon": {
                "metadata": {
                    "tags": [],
                    "concepts": []
                },
                "sys": {
                    "space": {
                        "sys": {
                            "type": "Link",
                            "linkType": "Space",
                            "id": "41chegrmhit0"
                        }
                    },
                    "id": "3jxVUxtVC5l8ybAIrPKYT2",
                    "type": "Asset",
                    "createdAt": "2024-10-10T08:19:57.032Z",
                    "updatedAt": "2024-10-17T14:49:13.313Z",
                    "environment": {
                        "sys": {
                            "id": "master",
                            "type": "Link",
                            "linkType": "Environment"
                        }
                    },
                    "publishedVersion": 7,
                    "revision": 2,
                    "locale": "en-US"
                },
                "fields": {
                    "title": "checklist",
                    "description": "",
                    "file": {
                        "url": "//images.ctfassets.net/41chegrmhit0/3jxVUxtVC5l8ybAIrPKYT2/839970da07452542c94e2a8fab778550/checklist.png",
                        "details": {
                            "size": 1064,
                            "image": {
                                "width": 64,
                                "height": 64
                            }
                        },
                        "fileName": "checklist.png",
                        "contentType": "image/png"
                    }
                }
            }
        }
    ],
    "socialMedia": [
        {
            "facebook": "https://www.facebook.com/TRAGEDEL.AFRIQUE/",
            "linkedin": "https://www.linkedin.com/company/tragedel/"
        }
    ],
    "websiteInformation": [
        {
            "name": "Tragedel",
            "email": "commercial.department@tragedel.com",
            "phone": "+216 36 365 635",
            "address": "Elysee Residence,1st Floor, Lake 1, Tunisia 1053",
            "presentation": "Since 1989,TRAGEDEL has been contributing with its team of talented engineers and consultants to the success of many construction projects. Acting with a wide range of quality services to satisfy you throughout a shared adventure, we guarantee the Novelty throughout the world of innovative solutions, personalized services and expert advice to build a solid foundation for a long-term business supported by human capital that meets your expectations and high-end resources"
        }
    ]
}