import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import { LANG } from '../../translation/translation';

function About({ data }) {
    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Company Overview</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/about.jpg"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == 'en' ? "Company Overview" : "Présentation de l'entreprise"}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == 'en' ? "Company Overview" : "Présentation de l'entreprise"}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="contact-with-area">
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 style={{ fontSize: '30px', textTransform: 'none', }}>{LANG == 'en' ? "The union of an innovative culture, a passion for execellence, a commitment to quality, and rigorous execution to attain unprecedented possibilities." : "L'union d'une culture innovante, d'une passion pour l'excellence, d'un engagement envers la qualité et d'une exécution rigoureuse pour atteindre des possibilités sans précédent."}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <img style={{ borderRadius: '8px', width: '450px' }} src='assets/img/hero/about1.jpg' />
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12" style={{ paddingTop: '60px' }}>
                                {LANG == 'en' ? "With 35 years of experience, TRAGEDEL has developed specific expertise and a highly qualified multidisciplinary team to meet the various needs of its customers. With more than 50 completed projects and several others in progress, we have prioritized our development on the African continent. TRAGEDEL focuses on the following areas of activity: electricity, renewable energy, construction, and water treatment." : "Forte de 35 années d'expérience, TRAGEDEL a développé une expertise spécifique et une équipe multidisciplinaire hautement qualifiée pour répondre aux différents besoins de ses clients. Avec plus de 50 projets réalisés et plusieurs autres en cours, nous avons priorisé notre développement sur le continent africain. TRAGEDEL se concentre sur les domaines d'activité suivants : électricité, énergies renouvelables, construction et traitement des eaux."}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="project-area section-padding30" style={{ marginTop: '-130px' }}>
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 className="">{LANG == "en" ? "Who we are" : "Qui nous sommes"}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ textAlign: 'center', alignContent: 'center' }}>
                            <div className="col-lg-4 col-md-6 col-sm-12 about-card" style={{ padding: '10px' }}>
                                <img src="/assets/img/icons/user.png" />
                                <h2 style={{ fontSize: "26px", marginTop: "24px", fontWeight: "503" }}>Vision</h2>
                                <div className='cut-line'></div>
                                <p style={{ fontSize: "20px" }}>{LANG == "en" ? "Our vision is to be the most passionately recommended leader in management and the execution of turnkey projects in African and Gulf countries." : "Nous visons être la plus passionnément référée dans le domaine du management et réalisation des projets clé en main dans les pays Africains & les pays de Golfe"}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 about-card" style={{ padding: '10px' }}>
                                <img src="/assets/img/icons/shield.png" />
                                <h2 style={{ fontSize: "26px", marginTop: "24px", fontWeight: "503" }}>Mission</h2>
                                <div className='cut-line'></div>
                                <p style={{ fontSize: "20px" }}>{LANG == "en" ? "Our mission is to accelerate sustainable growth by delivering high-quality water and energy management services at competitive prices. We are committed to meeting deadlines and fostering strong collaboration with our clients." : "Accélérer une croissance plus durable en fournissant des services de gestion de l’eau et de l’énergie de haute qualité à des prix compétitifs et en respectant les délais tout en assurant une solide collaboration avec nos clients."}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 about-card" style={{ padding: '10px' }}>
                                <img src="/assets/img/icons/globe.png" />
                                <h2 style={{ fontSize: "26px", marginTop: "24px", fontWeight: "503" }}>Values</h2>
                                <div className='cut-line'></div>
                                <p style={{ fontSize: "20px" }}>{LANG == "en" ? "We are committed to integrity, focusing on customer satisfaction, quality, and growth. We value human capital and prioritize transparency, trust, and sustainability with our stakeholders. Continuous improvement and environmental sustainability guide our actions." : "Nous sommes engagés envers l'intégrité, en nous concentrant sur la satisfaction client, la qualité et la croissance. Nous valorisons le capital humain et priorisons la transparence, la confiance et la durabilité avec nos parties prenantes. L'amélioration continue et la durabilité environnementale guident nos actions."}</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="contact-with-area" style={{ backgroundImage: 'url(assets/img/gallery/section-bg2.jpg' }}>
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{textAlign: 'center'}}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 className="">{LANG == "en" ? "History" : "histoir"}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <img style={{borderRadius: '8px', width: '450px'}} src='assets/img/hero/about1.jpg'/>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <h2>Kamel Charfeddine</h2>
                                {LANG == 'en' ? 
                                    <p>Since 1989, TRAGEDEL has contributed to the success of numerous construction projects, providing a wide range of fit-for-purpose services to satisfy our customers throughout a shared adventure. With the added value of our teams' skills and flexibility, we guarantee the reliability of the work we carry out, even in the most challenging environments and conditions.
                                    <br/> <br/> As we move towards a cleaner and more sustainable energy future, TRAGEDEL offers innovative solutions accompanied by a multitude of tailor-made services. Through our vision, we create sustainable value and recognize potential, particularly in electricity, renewable energy, and water treatment.
                                    </p> :
                                    <p>Fort de ses 32 ans d’expérience, TRAGEDEL a développé un savoir-faire et une équipe d’ingénieurs et de consultants répondant aux besoins de nos clients. Plus d’une cinquantaine de projets réalisés et plusieurs autres en cours ont favorisé notre développement sur le continent africain. <br/>
                                    <br/>À l’heure où nous évoluons vers un avenir énergétique propre et durable, TRAGEDEL garantit des solutions innovantes à la pointe de la technologie, accompagnées d’une multitude de services sur mesure. Grâce à notre vision, nous créons de la valeur et voyons le potentiel, particulièrement dans l'électricité, les énergies renouvelables et le traitement de l'eau potable.
                                    </p>
                                }
                             </div>
                        </div>
                    </div>
                </section> */}

                <div className="latest-news-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                <div className="section-tittle section-tittle7 mb-50">
                                    <div className="front-text">
                                        <h2 className="">{LANG == "en" ? "Certification" : "Attestation"}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {data?.news
                                .map((blog, index) => {
                                    const { title, titleFrench, images, date, category, categoryFrench } = blog;
                                    if (category == "Certification") {
                                        return (
                                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                <div style={{ alignContent: 'center', textAlign: 'center' }}>
                                                    <img src={blog.icon.fields.file.url} />
                                                    <h2 style={{ fontSize: "24px", marginTop: "24px", fontWeight: "503" }}>{LANG == "en" ? title : titleFrench}</h2>
                                                </div>
                                                <div className="single-news mb-30" style={{ height: '540px' }}>
                                                    <div className="news-img">
                                                        <a href={images[0].fields.file.url} target='_black'><img src={images[0].fields.file.url} alt="" /></a>
                                                        {/* <div className="news-date text-center">
                                                            <span>{date}</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                </div>

                <div className="slider-area section-padding30">
                    <video src='assets/videos/company-profile.mp4' autoPlay loop muted style={{ width: '99vw', aspectRatio: 'auto', height: '99vh', objectFit: 'fill' }}></video>
                </div>

                {/* <section className="project-area" style={{ marginBottom: '120px' }}>
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12" style={{textAlign: 'center'}}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <h2 className="">{LANG == "en" ? "Our Clients" : "Nos clients"}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-12">
                                <section className="customer-logos slider">
                                    {data?.clients
                                        .map((client, index) => {
                                            const { image } = client;
                                            return (
                                                <div className="slide"> <img style={{ width: '140px', height: '140px' }} src={image.fields.file.url} /> </div>
                                            );
                                        })}
                                </section>
                            </div> */}
                {/* <div className="col-12">
                                <div className="tab-content active" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="project-caption">
                                            <div className="row">
                                                {data?.clients
                                                    .sort((a, b) => {
                                                        // Sort to move "steg.jpg" to the end
                                                        if (a.image.fields.file.url.includes("societe-tunisienne-delectricite-et-de-gaz")) return 1;
                                                        if (b.image.fields.file.url.includes("societe-tunisienne-delectricite-et-de-gaz")) return -1;
                                                        return 0;
                                                    })
                                                    .map((client, index) => {
                                                        const { image } = client;
                                                        let url = image.fields.file.url;
                                                        return (
                                                            <div 
                                                                key={index}
                                                                className="col-lg-3 col-md-4 col-sm-12" 
                                                                style={{
                                                                    marginBottom: '25px', 
                                                                    alignContent: 'center',
                                                                    textAlign: 'center', 
                                                                    height: '150px'
                                                                }}
                                                            >
                                                                <img 
                                                                    src={url} 
                                                                    style={{ 
                                                                        width: '150px', 
                                                                        height: (url.includes("edm_848862243") ? '150px' : 'auto') 
                                                                    }} 
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </section>             */}
            </main>
        </Theme >
    );
}

export default About;
