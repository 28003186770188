import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import { LANG } from '../../translation/translation';

function Clients({ data }) {
    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Our Clients</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/about.jpg"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == 'en' ? "Our Clients " : "Nos clients"}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == 'en' ? "Overview of our clients" : "Aperçu de nos clients"}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area" style={{ paddingTop: '100px' }}>
                    <div className="container">
                        <div className="project-heading mb-35">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <p style={{ fontSize: '40px', fontFamily: 'auto', lineHeight: '2.2rem' }}>{LANG == "en" ? "Our Clients" : "Nos clients"}</p>
                                            {LANG == "en" ?
                                                <p style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 400, letterSpacing: 'normal', lineHeight: '28px', fontFamily: 'auto' }}>We continually identify and comply with our clients specifics needs, statutory and regulatory requirements. <br /> Our business approach with our clients are conducted openly and with integrity which leads to creating true partnerships with all our clients.</p>
                                                :
                                                <p style={{ fontSize: '18px', fontStyle: 'normal', fontWeight: 400, letterSpacing: 'normal', lineHeight: '28px', fontFamily: 'auto' }}>Nous identifions et respectons continuellement les besoins spécifiques de nos clients ainsi que les exigences légales et réglementaires. <br /> Notre approche commerciale avec nos clients est menée de manière ouverte et intègre, ce qui conduit à la création de véritables partenariats avec tous nos clients</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="project-heading mb-35" style={{ paddingTop: '50px' }}>
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
                                    <div className="section-tittle section-tittle3">
                                        <div className="front-text">
                                            <p style={{ fontSize: '40px', fontFamily: 'auto', lineHeight: '2.2rem' }}>{LANG == "en" ? "Explore our clients" : "Découvrez nos clients"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="tab-content active" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="project-caption">
                                            <div className="row">
                                                {data?.clients
                                                    .sort((a, b) => {
                                                        // Sort to move "steg.jpg" to the end
                                                        if (a.image.fields.file.url.includes("societe-tunisienne-delectricite-et-de-gaz")) return 1;
                                                        if (b.image.fields.file.url.includes("societe-tunisienne-delectricite-et-de-gaz")) return -1;
                                                        return 0;
                                                    })
                                                    .map((client, index) => {
                                                        const { image } = client;
                                                        let url = image.fields.file.url;
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="col-lg-3 col-md-4 col-sm-12"
                                                                style={{
                                                                    marginBottom: '25px',
                                                                    alignContent: 'center',
                                                                    textAlign: 'center',
                                                                    height: '150px'
                                                                }}
                                                            >
                                                                <img
                                                                    src={url}
                                                                    style={{
                                                                        width: '150px',
                                                                        height: (url.includes("edm_848862243") ? '150px' : 'auto')
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </Theme >
    );
}

export default Clients;
