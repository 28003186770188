import React, { useEffect, useState } from 'react';
import Theme from '../../theme/Theme';
import { LANG } from '../../translation/translation';
import { Link } from 'react-router-dom';

function Project({ data }) {
    const [project, setProject] = useState(null);

    useEffect(() => {
        let selected = localStorage.getItem('selected-project')
        setProject(JSON.parse(selected))
    }, [])

    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">{LANG == 'en' ? "Home" : "Accueil"}</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ minHeight: '300px', backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/ser.png"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>Project Overview</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">Project Overview</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="contact-with-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="slider-area">
                                    <div className="slider-active project-slider">
                                        {project?.images?.map((image, index) => (
                                            <div className="single-slider slider-height d-flex align-items-center" style={{ textAlign: 'center', color: 'white', backgroundImage: `url(${image.fields?.file?.url})` }}>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="services-details-area">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="single-services section-padding2" style={{ paddingTop: '10px' }}>
                                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="project-tab" data-toggle="tab" href="#project" role="tab" aria-controls="project" aria-selected="true">Project</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" style={{ color: 'red' }} id="client-tab" data-toggle="tab" href="#client" role="tab" aria-controls="client" aria-selected="false">Client</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" style={{ color: 'red' }} id="funder-tab" data-toggle="tab" href="#funder" role="tab" aria-controls="funder" aria-selected="false">Funder</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade show active" id="project" role="tabpanel" aria-labelledby="project-tab" style={{ padding: '20px' }}>
                                                            <h2 style={{ color: 'red', fontSize: '25px' }}>{LANG == "en" ? project?.name : project?.nameFrench}</h2>
                                                            {/* <p style={{ color: 'black' }}>{LANG == "en" ? project?.description1 : project?.descriptionFrench}</p> */}
                                                        </div>
                                                        <div className="tab-pane fade" id="client" role="tabpanel" aria-labelledby="client-tab" style={{ padding: '20px' }}>
                                                            <div>
                                                                <h2 style={{ color: 'red' }}>{project?.client?.fields?.title}</h2>
                                                                <img src={project?.client?.fields?.file?.url} />
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="funder" role="tabpanel" aria-labelledby="client-tab" style={{ padding: '20px' }}>
                                                            <div>
                                                                <h2 style={{ color: 'red' }}>{project?.funder?.fields?.title}</h2>
                                                                <img src={project?.funder?.fields?.file?.url} style={{ width: '400px' }} />
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="owner" role="tabpanel" aria-labelledby="client-tab" style={{ padding: '20px' }}>
                                                            <div>
                                                                <h2 style={{ color: 'red' }}>{project?.owner?.fields?.title}</h2>
                                                                <img src={project?.owner?.fields?.file?.url} style={{ width: '400px' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Theme>
    );
}

export default Project;