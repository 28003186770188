import React from 'react';
import Theme from '../../theme/Theme';
import { Link } from 'react-router-dom';
import { LANG } from '../../translation/translation';

function ChairmanDetail({ data }) {
    return (
        <Theme data={data}>
            <main>
                <h1 className="entry-title">Our Chairman</h1>
                <div className="slider-area ">
                    <div className="single-slider hero-overly slider-height2 d-flex align-items-center"
                        style={{ backgroundSize: 'cover', background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${"assets/img/hero/heor2.jpg"}) center/cover no-repeat fixed` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap pt-100">
                                        <h2>{LANG == "en" ? "Chairman’s Message" : "Message du président"}</h2>
                                        <nav aria-label="breadcrumb ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item"><Link to="/">{LANG == 'en' ? "Home" : "Accueil"}</Link></li>
                                                <li className="breadcrumb-item"><Link href="#">{LANG == "en" ? "Chairman’s Message" : "Message du président"}</Link></li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="project-area" style={{ paddingTop: '140px', paddingBottom: '140px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ display: 'inline-flex' }}>
                                    <img src="assets/img/hero/chairman1.jpg" style={{ borderRadius: '10px', width: '230px' }} />
                                    <div style={{ top: '180px', left: '257px', position: 'absolute' }}>
                                        <h2 style={{ fontSize: '28px' }}>Mr. Kamel Charfeddine</h2>
                                        <h3 style={{ color: 'red', fontSize: '24px' }}>{LANG == "en" ? "Chairman" : "Président"}</h3>
                                    </div>
                                </div>
                                <div style={{ marginTop: '50px' }}>
                                    {LANG == 'en' ? <>
                                        <p>Since 1989, TRAGEDEL has contributed to the success of numerous construction projects, providing a wide range of fit-for-purpose services to satisfy our customers throughout a shared adventure. With the added value of our teams' skills and flexibility, we guarantee the reliability of the work we carry out, even in the most challenging environments and conditions.</p>
                                        <p>As we move towards a cleaner and more sustainable energy future, TRAGEDEL offers innovative solutions accompanied by a multitude of tailor-made services. Through our vision, we create sustainable value and recognize potential, particularly in electricity, renewable energy, and water treatment.</p>
                                    </> : <>
                                        <p>Fort de ses 32 ans d’expérience, TRAGEDEL a développé un savoir-faire et une équipe d’ingénieurs et de consultants répondant aux besoins de nos clients. Plus d’une cinquantaine de projets réalisés et plusieurs autres en cours ont favorisé notre développement sur le continent africain.</p>
                                        <p>À l’heure où nous évoluons vers un avenir énergétique propre et durable, TRAGEDEL garantit des solutions innovantes à la pointe de la technologie, accompagnées d’une multitude de services sur mesure. Grâce à notre vision, nous créons de la valeur et voyons le potentiel, particulièrement dans l'électricité, les énergies renouvelables et le traitement de l'eau potable.</p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Theme >
    );
}

export default ChairmanDetail;
