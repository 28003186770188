import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { convertToSlug } from '../../Utils';
import { LANG } from '../../translation/translation';

function Service1({ service, key }) {
    const navigate = useNavigate();


    const handleServiceClick = () => {
        localStorage.setItem('selected-service', JSON.stringify(service));
        navigate(`/service`);
    };

    return (
        <div key={key} className="col-lg-6 col-md-6">
            <div className="single-project mb-30">
                <div className="project-img">
                    <img src={service.image.fields.file.url} style={{ height: '260px' }} alt="" />
                </div>
                <div className="project-cap">
                    <button style={{ background: 'transparent', border: 'none' }} onClick={handleServiceClick}>
                        <Link className="plus-btn">
                            <i className="ti-plus"></i>
                        </Link>
                    </button>
                    <h4 style={{ marginTop: '-25px' }}>
                        <Link onClick={handleServiceClick}>
                            {LANG == "en" ? service.name : service.titleFrench}
                        </Link>
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default Service1;
